import React from "react";
import { Routes, Route } from "react-router-dom";

import { CreateTemplates } from "../components/templates/CreateTemplates";
import { ViewTemplates } from "../components/templates/ViewTemplates";
import { ListTemplates } from "../components/templates/ListTemplates";
import { EditTemplates } from "../components/templates/EditTemplates";
import { permisos } from "../helper/permissions";

import { Navbar } from "../components/ui/Navbar";
import { SideBarr } from "../components/ui/SideBarr";

export const TemplatesRoutes = () => {
  const hasCrearPermission = permisos('Plantillas', 'crear')
  return (
    <div className="flex "> 
      <SideBarr titulo={"Certificados"} />
      <div className={`h-screen w-full`}>
        <Navbar titulo={"Plantillas IU Digital"} nameModule={'Plantilla'} phat={'/templates/create'} butonCreate={true} hasCrearPermission={hasCrearPermission} />
        <Routes>
          <Route path="list" element={<ListTemplates />} />
          <Route path="" element={<ListTemplates />} />
          <Route path="view/:id" element={<ViewTemplates />} />
          <Route path="edit/:id" element={<EditTemplates />} />
          <Route path="create" element={<CreateTemplates />} />
        </Routes>
      </div>
    </div>
  );
};