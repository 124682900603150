
import swal from "sweetalert";

export function permisos(seccion, accion = '', returned = '') {

  const user = JSON.parse(localStorage.getItem('user'));
  
  let hasPermission = false;

  if (user.role_id == 1) {
    return true;
  } else {
    if (accion == '') {
      hasPermission = (user && user.permissions && user.permissions[seccion]) ? true : false;
    } else {
      hasPermission = user && user.permissions && user.permissions[seccion] && user.permissions[seccion].includes(accion);
    }


    if (returned == 'alerta') {
      if (!hasPermission) {
        swal({
          title: "Alerta!!!",
          text: `Esta sección está restringida`,
          icon: "warning",
          dangerMode: true,
        }).then(function () {
          window.location = "/bienvenido";
        });

      }
    } else {
      return hasPermission;
    }
  }
}