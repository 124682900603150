import React from "react";
import { useNavigate } from "react-router-dom";

export const BackToPage = ({texto}) => {
    const navigate = useNavigate();
    const handleReturn = () => {
        navigate( -1 );
    }
  return (
    <button
    type="button"
      onClick={handleReturn}
      className="bg-[#AD2A3F] rounded px-6 py-2 mt-5 mx-2 text-base text-white Metropolis-Bold"
    >
      {texto}
    </button>
  );
};
