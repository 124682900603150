import React from "react";
import { Routes, Route } from "react-router-dom";
import { Sync } from "../components/sync/sync";

import { Navbar } from "../components/ui/Navbar";
import { SideBarr } from "../components/ui/SideBarr";


export const SyncRoutes = () => {

  return (
    <div className="flex ">
      <SideBarr titulo={"Sincronizar"} />
      <div className={`h-screen w-full`}>
        <Navbar titulo={"Sincronización IU Digital"} nameModule={'Sincronizacion'}  />
        <Routes>
          <Route path="" element={<Sync />} />
        </Routes>
      </div>
    </div>
  );
};
