import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import { BackToPage } from "../generals/backToPage/BackToPage";
import {
  createCertificates,
  getAreasService,
  getIstanceService,
} from "../../selectors/getCertificates";

import { getCoursesCanvas } from "../../selectors/getSync";
import { getPlantillasService } from "../../selectors/pantillasServices";
import { getAllImagesStorageNotPaginateService } from "../../selectors/imageStorageService";
import "./Certificates.css";
import { permisos } from "../../helper/permissions";

export const CreateCertificates = () => {

  const seccion = 'Cursos'
  permisos(seccion, 'listar', 'alerta')
  const hasListarPermission = permisos(seccion, 'listar')
  const hasEditarPermission = permisos(seccion, 'editar')
  const hasVerPermission = permisos(seccion, 'ver')
  const hasBorrarPermission = permisos(seccion, 'borrar')
  const hasCrearPermission = permisos(seccion, 'crear')


  const navigate = useNavigate();
  const [plantillas, setPlantillas] = useState([]);
  const [imagenes, setImagenes] = useState([]);
  const [instancias, setInstancias] = useState([]);
  const [areas, setAreas] = useState([]);

  const [camposInvalidosForm, setCamposInvalidosForm] = useState([]);

  const estilo =
    "Metropolis-Bold placeholder:text-slate-400 block bg-white w-full rounded-md mx-1 py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm";

  useEffect(() => {
    getIstanceService().then((data) => { //Listar las instancias para el select
      if (data.data.success) {
        setInstancias(data.data.data);
      }
    }).catch((error) => {
      swal({
        title: "Error",
        text: `Sucedió un error: ${error}`,
        icon: "error",
        dangerMode: true,
      });
    });
    getAreasService().then((data) => { //listar las areas para el select
      if (data.data.success) {
        setAreas(data.data.data);
      }
    }).catch((error) => {
      swal({
        title: "Error",
        text: `Sucedió un error: ${error}`,
        icon: "error",
        dangerMode: true,
      });
    });
    getPlantillasService() //listar las plantillas para el select
      .then((resp) => {
        if (resp.data.success) {
          setPlantillas(resp.data.data);
        }
      })
      .catch((error) => {
        swal({
          title: "Error",
          text: `Sucedió un error: ${error}`,
          icon: "error",
          dangerMode: true,
        });
      });
    getAllImagesStorageNotPaginateService() //listar las imágenes de firmas y logos para el select
      .then((resp) => {
        console.log(resp)
        if (resp.data.success) {
          setImagenes(resp.data.data);
        }
      })
      .catch((error) => {
        swal({
          title: "Error",
          text: `Sucedió un error: ${error}`,
          icon: "error",
          dangerMode: true,
        });
      });
  }, []);

  const [formValues, handleInputChange] = useForm({
    cursoId: "",
    canvasId: "",
    codigoCurso: "",
    instancia_id: "",
    area_id: "",
    nombreCurso: "",
    nombreCurso2: "",
    minima_nota: "",
    plantillaId: "",
    horas: "",
    fechaCertificacion: "",
    texto1: "",
    texto2: "",
    texto3: "",
    url_encuesta: "N/A",
    sincronizar: "",
  });

  let {
    area_id,
    instancia_id,
    cursoId,
    canvasId,
    codigoCurso,
    nombreCurso,
    nombreCurso2,
    plantillaId,
    minima_nota,
    horas,
    fechaCertificacion,
    texto1,
    texto2,
    texto3,
    url_encuesta,
    sincronizar,
  } = formValues;

  const handleOnBlur = (e) => {
    /*  e.preventDefault();*/
    let camposInvalidos = obtenerCamposInvalidosDelForm(formValues);
    console.log("Campos Invalidos: " + JSON.stringify(camposInvalidos));
    if (camposInvalidos.length > 0) {
      setCamposInvalidosForm(camposInvalidos);
    }
  }

  const handleCreate = (e) => {
    e.preventDefault();
    let camposInvalidos = obtenerCamposInvalidosDelForm(formValues);//verificamos campos estén correctos
    if (camposInvalidos.length > 0) {
      setCamposInvalidosForm(camposInvalidos);
    } else {
      formValues.plantillaId = parseFloat(formValues.plantillaId);
      createCertificates(formValues)//enviamos el formulario para guardar
        .then((resp) => {
          if (resp.data.success) {
            swal(
              `Certificado ${formValues.nombreCurso} ${formValues.nombreCurso2}, Creado`,
              {
                icon: "success",
              }
            );
            navigate("../");
          } else {
            if (resp.data.code == 401) {
              swal({
                title: "Alerta!!!",
                text: `${resp.data.data.message}`,
                icon: "warning",
                dangerMode: true,
              }).then(function () {
                window.location = "/bienvenido";
              });
            } else {
              swal({
                title: "Error",
                text: resp.data.data[0],
                icon: "error",
                dangerMode: true,
              });
            }

          }
        })
        .catch((error) => {
          swal({
            title: "Error",
            text: `Sucedió un error: ${error}`,
            icon: "error",
            dangerMode: true,
          });
        });
    }
  };

  const obtenerCamposInvalidosDelForm = (formValues) => {

    let camposInvalidos = [];
    if (formValues.codigoCurso === "") {
      camposInvalidos.push("codigoCurso");
    }
    if (formValues.nombreCurso === "") {
      camposInvalidos.push("nombreCurso");
    }
    if (formValues.plantillaId === "") {
      camposInvalidos.push("plantillaId");
    }
    if (formValues.minima_nota === "") {
      camposInvalidos.push("minima_nota");
    }
    if (formValues.horas === "") {
      camposInvalidos.push("horas");
    }
    if (formValues.fechaCertificacion === "") {
      camposInvalidos.push("fechaCertificacion");
    }
    if (formValues.sincronizar === "") {
      camposInvalidos.push("sincronizar");
    }
    if (formValues.instancia_id === "") {
      camposInvalidos.push("instancia_id");
    }
    if (formValues.area_id === "") {
      camposInvalidos.push("area_id");
    }

    return camposInvalidos;
  };

  const onKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode >= 48 && keyCode <= 57) {
      return true;
    } else if (keyCode == 46) {
      return true;
    } else {
      event.preventDefault();
    }
  }


  function handleClick(event) {
    event.preventDefault();
    if (formValues.canvasId === "") {
      swal({
        title: "Error",
        text: `Ingresa un id para buscar`,
        icon: "error",
        dangerMode: true,
      });
    } else {
      getCoursesCanvas(formValues.canvasId).then((resp) => {
        //console.log(resp.data.errors[0].message);
        if (resp.data && resp.data.errors) {
          swal({
            title: "Error",
            text: `Sucedió un error: ${resp.data.errors[0].message}`,
            icon: "error",
            dangerMode: true,
          });
        } else {
          formValues.codigoCurso = resp.data.course_code;
          formValues.nombreCurso = resp.data.name;

          handleInputChange({
            target: {
              name: 'nombreCurso',
              value: resp.data.name
            }
          });
          handleInputChange({
            target: {
              name: 'sincronizar',
              value: 1
            }


          });

        }


      });
    }

  }


  return (
    <>
      {camposInvalidosForm.includes(
        "codigoCurso",
        "nombreCurso",
        "plantillaId",
        "minima_nota",
        "horas",
        "fechaCertificacion",
        "sincronizar",
        "area_id",
        "instancia_id"
      ) && (
          <span className="flex bg-[#FBE9EA] px-6 py-2 mt-5 ml-10 mr-10 border-l-4 border-l-rose-600 text-base text-[#4d4d4d] Metropolis">
            <b>Advertencia:</b>Los campos marcados en Rojo son Obligatorios
          </span>
        )}
      {
        hasCrearPermission && (
          <div className="mt-3">
            <h1 className="text-base lg:text-3xl my-5 text-center Metropolis-Extra-Bold">
              Crear Curso
            </h1>
            <div className="flex mt-3">
              <div className="lg:w-3/4  lg:m-auto px-5 py-5 rounded-2xl bg-[#67b5dd]">
                <p className='Metropolis-Bold text-1xl'>Campo Opcional (*)</p>
                <br />
                <div className="flex">
                  <div className="d-sm-none lg:w-2/6 rounded-lg  max-full mx-2 bg-[#243f59] Metropolis-Bold text-2xl text-white leading-loose">
                    <ul>
                      <li className="ml-3">
                        <label>Canvas id</label>
                      </li>
                      <li className="ml-3">
                        <label>Código curso</label>
                      </li>
                      <li className="pl-3">
                        <label>Nombre curso 1</label>
                      </li>
                      <li className="pl-3">
                        <label>Nombre curso 2 (*)</label>
                      </li>
                      <li className="pl-3">
                        <label>Plantilla</label>
                      </li>
                      <li className="ml-3">
                        <label>Nota aprobatoria <sub>nota máxima 5</sub></label>
                      </li>
                      <li className="pl-3">
                        <label>Horas</label>
                      </li>
                      <li className="pl-3">
                        <label>Fecha</label>
                      </li>
                      <li className="pl-3">
                        <label>Texto 1</label>
                      </li>
                      <li className="pl-3">
                        <label>Logos (*)</label>
                      </li>
                      <li className="pl-3">
                        <label>Firmas (*)</label>
                      </li>
                      <li className="pl-3">
                        <label>Encuesta (*)</label>
                      </li>
                      <li className="ml-3">
                        <label>Sincronización</label>
                      </li>
                      <li className="ml-3">
                        <label>Área</label>
                      </li>
                      <li className="ml-3">
                        <label>Instancia</label>
                      </li>
                    </ul>
                  </div>
                  <div className="lg:w-4/6 w-6/6">
                    <form onSubmit={handleCreate}>
                      <div className="mb-3 inputcanvas">
                        <input type="hidden" value={cursoId} />
                        <input
                          type="text"
                          placeholder="Canvas Id"
                          className={
                            camposInvalidosForm.includes("canvasId")
                              ? estilo + " border-2 border-rose-600"
                              : estilo
                          }
                          name="canvasId"
                          autoComplete="off"
                          value={canvasId}
                          onChange={handleInputChange}
                          title="Canvas Id"
                        />
                        <button onClick={handleClick} className="bg-[#93b33a] rounded px-6 py-2 text-base text-white Metropolis-Bold">Busca el id en Canvas</button>
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          placeholder="Código Curso"
                          className={
                            camposInvalidosForm.includes("codigoCurso")
                              ? estilo + " border-2 border-rose-600"
                              : estilo
                          }
                          name="codigoCurso"

                          autoComplete="off"
                          value={codigoCurso}
                          onChange={handleInputChange}
                          onBlur={handleOnBlur}
                          title="Código Curso"
                        />
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          placeholder="Nombre Curso1"
                          className={
                            camposInvalidosForm.includes("nombreCurso")
                              ? estilo + " border-2 border-rose-600"
                              : estilo
                          }

                          name="nombreCurso"
                          autoComplete="off"
                          value={nombreCurso}
                          onChange={handleInputChange}
                          onBlur={handleOnBlur}
                          title="Nombre Curso1"
                        />
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          placeholder="Nombre Curso 2"
                          className="Metropolis-Bold placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md mx-1 py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                          name="nombreCurso2"
                          autoComplete="off"
                          value={nombreCurso2}
                          onChange={handleInputChange}
                          title="Nombre Curso 2"
                        />
                      </div>
                      <div className="mb-3">
                        <select
                          className={
                            camposInvalidosForm.includes("plantillaId")
                              ? estilo + " border-2 border-rose-600"
                              : estilo
                          }
                          aria-label="Default select example"
                          name="plantillaId"
                          autoComplete="off"
                          value={plantillaId}
                          onChange={handleInputChange}
                          onBlur={handleOnBlur}
                          title="plantilla"
                        >
                          <option value="">Seleccione Plantilla</option>
                          {plantillas.map((plantilla, index) => {
                            return (
                              <option
                                key={plantilla.plantillaId}
                                value={plantilla.plantillaId}
                              >
                                {plantilla.nombrePlantilla}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="mb-3">
                        <input
                          type="number"
                          placeholder="Nota aprobatoria (max 5)"
                          onKeyPress={(event) => { onKeyPress(event) }}
                          className={
                            camposInvalidosForm.includes("minima_nota")
                              ? estilo + " border-2 border-rose-600"
                              : estilo
                          }
                          name="minima_nota"
                          autoComplete="off"
                          max="5"
                          min="0"
                          step=".01"
                          value={minima_nota}
                          onChange={handleInputChange}
                          onBlur={handleOnBlur}
                          title="Nota aprobatoria (max 5)"
                        />
                      </div>
                      <div className="mb-3">
                        <input
                          type="number"
                          placeholder="Horas"
                          onKeyPress={(event) => { onKeyPress(event) }}

                          className={
                            camposInvalidosForm.includes("nombreCurso")
                              ? estilo + " border-2 border-rose-600"
                              : estilo
                          }

                          name="horas"
                          autoComplete="off"
                          max="9999"
                          min="0"
                          step="1"
                          pattern="/[0-9]+/"
                          value={horas}
                          onChange={handleInputChange}
                          onBlur={handleOnBlur}
                          title="Horas"
                        />
                      </div>
                      <div className="mb-3">
                        <input
                          type="date"
                          placeholder="Fecha de Certificado"
                          className={
                            camposInvalidosForm.includes("fechaCertificacion")
                              ? estilo + " border-2 border-rose-600"
                              : estilo
                          }

                          name="fechaCertificacion"
                          autoComplete="off"
                          value={fechaCertificacion}
                          onChange={handleInputChange}
                          onBlur={handleOnBlur}
                          title="Fecha de Certificado"
                        />
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          placeholder="Texto 1"
                          className={
                            camposInvalidosForm.includes("texto1")
                              ? estilo + " border-2 border-rose-600"
                              : estilo
                          }

                          name="texto1"
                          autoComplete="off"
                          value={texto1}
                          onChange={handleInputChange}
                          onBlur={handleOnBlur}
                          title="Texto 1"
                        />
                      </div>
                      <div className="mb-3">
                        <select
                          className="Metropolis-Bold placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md mx-1 py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                          name="texto2"
                          autoComplete="off"
                          value={texto2}
                          onChange={handleInputChange}
                          onBlur={handleOnBlur}     
                           title="Logo"
                    
                        >
                          <option value="" >Seleccione Logo</option>
                          {imagenes.map((imagen, index) => {
                            if (imagen.type == "Logos") {
                              return (
                                <option key={imagen.id} value={imagen.picture}>
                                  {imagen.title}
                                </option>
                              );
                            }
                          })}
                        </select>
                      </div>
                      <div className="mb-3">
                        <select
                          className="Metropolis-Bold placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md mx-1 py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                          name="texto3"
                          autoComplete="off"
                          value={texto3}   
                          title="Firma"
                          onChange={handleInputChange}
                          onBlur={handleOnBlur}
                        >
                          <option value="" >Seleccione Firma</option>
                          {imagenes.map((imagen, index) => {
                            if (imagen.type == "Firmas") {
                              return (
                                <option key={imagen.id} value={imagen.picture}>
                                  {imagen.title}
                                </option>
                              );
                            }
                          })}
                        </select>
                      </div>

                      <div className="mb-3">
                        <input
                          type="text"
                          placeholder="Url Encuesta"
                          className="Metropolis-Bold placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md mx-1 py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                          name="url_encuesta"
                          autoComplete="off"
                          value={url_encuesta}
                          onChange={handleInputChange}   
                          title="Url Encuesta"                       
                        />
                      </div>
                      <div className="mb-3">
                        <select
                          placeholder="sincronizar"
                          className={
                            camposInvalidosForm.includes("sincronizar")
                              ? estilo + " border-2 border-rose-600"
                              : estilo
                          }

                          name="sincronizar"
                          autoComplete="off"
                          value={sincronizar}
                          onChange={handleInputChange}
                          title="sincronizar"
                        >
                          <option value="">Seleccione un estado</option>
                          <option value="1">✅ Activo</option>
                          <option value="0">❌ Inactivo</option>
                        </select>
                      </div>
                      <div className="mb-3">
                        <select
                          className={
                            camposInvalidosForm.includes("area_id")
                              ? estilo + " border-2 border-rose-600"
                              : estilo
                          }
                          name="area_id"
                          autoComplete="off"
                          value={area_id}
                          onChange={handleInputChange}

                        >
                          <option value="">Seleccione Area</option>
                          {areas.map((area, index) => {
                            return (
                              <option key={index} value={area.id}>
                                {area.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="mb-3">
                        <select
                          className={
                            camposInvalidosForm.includes("instancia_id")
                              ? estilo + " border-2 border-rose-600"
                              : estilo
                          }
                          autoComplete="off"
                          name="instancia_id"
                          value={instancia_id}
                          onChange={handleInputChange}

                        >
                          <option value="">Seleccione Instancia</option>
                          {instancias.map((instancia, index) => {
                            return (
                              <option key={index} value={instancia.id}>
                                {instancia.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <input
                        className="bg-[#93b33a] rounded px-6 py-2 text-base text-white Metropolis-Bold"
                        type="submit"
                        value={"Guardar"}
                      />
                      <BackToPage texto="Cancelar" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }

    </>
  );
};
