import React, { useState, useEffect } from "react";
import swal from 'sweetalert';
import { Link } from "react-router-dom";
import { syncCertificatesService } from "../../selectors/getSync";
import { permisos } from "../../helper/permissions";
import { useNavigate } from "react-router-dom";
import loading from "../../assets/img/loading.svg"

export const Sync = () => {

  console.log('sincro');
  const seccion = 'Sincronizacion'
  permisos(seccion, 'estudiantes', 'alerta')
  const hasBtnPermission = permisos(seccion, 'estudiantes')
  const [dataMsg, setdataMsg] = useState([]);
  const navigate = useNavigate();
  const [showLoading, setshowLoading] = useState(false);


  const [showText, setShowText] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowText(true);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  const handleClick = () => {

    swal({
      title: "Alerta!!!",
      text: "¿Está seguro que desea sincronizar?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        setshowLoading(true);
        if (willDelete) {
          swal( {
            type: "warning",
            title: "Sincronizando",
            text: 'esta accion puede tomar unos minutos',
            icon: "info",
            buttons: false,
            timer: 2000000,
            imageUrl: loading,
          });
          syncCertificatesService().then((resp) => 
        {if (resp.data.success) {
                swal( {
                  title: "Exito",
                  text: 'Sincronizacion realizada con Éxito',
                  icon: "success",
                });
              }else{
              if (resp.data.data[0]) {
                swal({
                  title: "Error",
                  text: resp.data.data[0],
                  icon: "error",
                  dangerMode: true
                });
              } else {

                if (resp.data.code === 401) {
                  swal({
                    title: "Alerta!!!",
                    text: `${resp.data.data.message}`,
                    icon: "warning",
                    dangerMode: true,
                  }).then(function () {
                    window.location = "/bienvenido";
                  });
                }
                if (resp.data.code == 404) {
                  swal({
                    title: "Error",
                    text: `No hay cursos con conexion a Canvas`,
                    icon: "error",
                    dangerMode: true,
                  })
                }
                else {
                  swal({
                    title: "Informacion",
                    text: `${resp.data.data.message}`,
                    icon: "info",
                    dangerMode: true
                  });
                }


              }


            }
            setshowLoading(false);
          }).catch((error) => {
            swal({
              title: "Error",
              text: `Sucedió un error: ${error}`,
              icon: "error",
              dangerMode: true,
            });
          });

        } else {
          setshowLoading(false);
          swal("No se alteró la base de datos");
        }
      });
  };

  return (
    <div className="flex flex-col px-5 mt-5">
      <h1 className="text-center text-base lg:text-3xl my-5 Metropolis-Extra-Bold">
      Sincronizar Estudiantes</h1>
      {
        hasBtnPermission && (
          <div className="overflow-x-auto content-center text-center">
            <div>
              {showLoading ? (<div><img className="w-full h-48" src="assets/images/loading.svg" alt="" /></div>) : <div className="p-5 inline-block min-w-full bg-[#67b5dd] rounded-[12px] mb-5">
              <button onClick={handleClick}
                className="bg-[#243F59] rounded  px-3 py-2 text-base text-white Metropolis-Bold mb-3 mt-3 ml-1 mr-1">Presione para sincronizar</button>
            </div>}
            </div>
          </div>



        )
      }
    </div>
  );
};

