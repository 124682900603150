export const Menu = [{
    name: "Cursos",
    icon: 'details.png',
    service: 'Cursos',
    content: [{
        phat: '/certificate',
        name: 'Listado Cursos',
        service: 'listar'

    },
    {
        phat: '/certificate/create',
        name: 'Crear Curso',
        service: 'crear'
    },
    {
        phat: '/certificate/list-students',
        name: 'Certificados',
        service: 'estudiantes'
    },
    ]
},

{
    name: "Estudiantes",
    icon: 'students.png',
    service: 'Estudiantes',
    content: [{
        phat: '/students',
        name: 'Listado de Estudiantes',
        service: 'listar'
    },

    {
        phat: '/students/create',
        name: 'Crear Estudiante',
        service: 'crear'
    },
    ]
},

{
    name: "Plantillas",
    icon: 'certificate.png',
    service: 'Plantillas',
    content: [{
        phat: '/templates',
        name: 'Listado de plantillas',
        service: 'listar'

    },

    {
        phat: '/templates/create',
        name: 'Crear plantilla',
        service: 'crear'
    },
    ]
},

{

    name: "Carga Masiva",
    icon: 'move.png',
    service: 'Carga',
    content: [{
        phat: '/bulk-load/student-certificates',
        name: 'Estudiantes y Certificados',
        service: 'estudiantes'
    }, {
        phat: '/bulk-load/cursos',
        name: 'Cursos',
        service: 'cursos'
    }]
},
{
    name: "Galería de Imágenes",
    icon: "gallery.png",
    service: 'Galeria',
    content: [{
        phat: '/image-storage',
        name: 'Listado de Imágenes',
        service: 'listar'
    },
    {
        phat: '/image-storage/create',
        name: 'Crear Imagen',
        service: 'crear'
    }

    ]
},
{
    name: "Roles",
    icon: 'user.png',
    service: 'Roles',
    content: [{
        phat: '/roles',
        name: 'Listado de roles',
        service: 'listar'

    },

    {
        phat: '/roles/create',
        name: 'Crear Rol',
        service: 'crear'
    },
    ]
},
{
    name: "Usuarios",
    icon: 'user.png',
    service: 'Usuarios',
    content: [{
        phat: '/users',
        name: 'Listado de Usuarios',
        service: 'listar'

    },

    {
        phat: '/users/create',
        name: 'Crear Usuario',
        service: 'crear'
    },
    ]
},
{
    name: "Sincronización",
    icon: 'user.png',
    service: 'Sincronizacion',
    content: [{
        phat: '/sincronizacion',
        name: 'Estudiantes',            
        service: 'estudiantes'
    }]
}]