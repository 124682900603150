import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useParams } from "react-router-dom";
import { VisualizationOfStudentsByCourseService } from "../../selectors/getCertificates";
import { BackToPage } from "../generals/backToPage/BackToPage";
import { permisos } from "../../helper/permissions";

export const VisualizationOfStudentsByCourse = () => {
  const [listadoEstudiantes, setListadoEstudiantes] = useState([]);
  const [page, setPages] = useState({});
  const params = useParams();
  let classBg = "bg-[#fff]";
  useEffect(() => {
    VisualizationOfStudentsByCourseService(parseFloat(params.id),0)
      .then((resp) => {
        if (resp.data.success) {
          setListadoEstudiantes(resp.data.data.estudiantes.data);
          setPages(resp.data.data.estudiantes.paginacion);
        }
      })
      .catch((error) => {
         swal({
            title: "Error",
            text: `Sucedió un error: ${error}`,
            icon: "error",
            dangerMode: true,
          });
      });
  }, []);
  /**
   * Paginación 
   */
   const handleBackPage = (pageBack) => {
    VisualizationOfStudentsByCourseService(parseFloat(params.id), pageBack-1)
      .then((resp) => {
        if (resp.data.success) {
          setListadoEstudiantes(resp.data.data.estudiantes.data);
          setPages(resp.data.data.estudiantes.paginacion);
        }
      })
      .catch((error) => {
         swal({
            title: "Error",
            text: `Sucedió un error: ${error}`,
            icon: "error",
            dangerMode: true,
          });
      });
  }
  const handleNextPage = (page) => {
    VisualizationOfStudentsByCourseService(parseFloat(params.id),page+1)
      .then((resp) => {
        if (resp.data.success) {
          setListadoEstudiantes(resp.data.data.estudiantes.data);
          setPages(resp.data.data.estudiantes.paginacion);
        }
      })
      .catch((error) => {
         swal({
            title: "Error",
            text: `Sucedió un error: ${error}`,
            icon: "error",
            dangerMode: true,
          });
      });
  }
  return (
    <>
      <div className="flex flex-col px-8 mt-5">
        <div className="overflow-x-auto">
          <div className="p-8 inline-block min-w-full bg-[#67b5dd] rounded-[12px] ">
            <h1 className="text-3xl Metropolis-Extra-Bold color-titulos mb-4">
              Listado de estudiantes asociados al curso{" "}
              {listadoEstudiantes[0]?.codigoCurso}
            </h1>
            <div className="overflow-hidden">
              <table className="min-w-full border text-center rounded-[12px] overflow-hidden">
                <thead className="border-b bg-[#243f59] rounded-[12px]">
                  <tr>
                    <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                      #
                    </th>
                    <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                      Nombres
                    </th>
                    <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                      Apellidos
                    </th>
                    <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                      Documento
                    </th>
                    <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                      Correo
                    </th>
                    <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                      Calificacion
                    </th>
                  </tr>
                </thead>
                <tbody className="border-b bg-[#fff]">
                  {listadoEstudiantes.map((estudiante, index) => {
                    if (index % 2 === 0) {
                      classBg = "bg-[#ececec]";
                    } else {
                      classBg = "bg-[#fff]";
                    }
                    return (
                      <tr key={index} className={`border-b ${classBg}`}>
                        <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r bg-[#243f5926]">
                          {index + 1}
                        </td>
                        <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r bg-[#243f5926]">
                          {estudiante.info.nombres}
                        </td>
                        <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r bg-[#243f5926]">
                          {estudiante.info.apellidos}
                        </td>
                        <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r bg-[#243f5926]">
                          {estudiante.info.documento}
                        </td>
                        <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r bg-[#243f5926]">
                          {estudiante.info.correo}
                        </td>
                        <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r bg-[#243f5926]">
                          {estudiante.calificacion}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {page.onFirstPage !== true ? <button  onClick={(event) =>handleBackPage(page.currentPage)} className="bg-[#93b33a] rounded px-6 py-2 text-base text-white Metropolis-Bold mt-5">
                Atras
              </button> :''}
            {
              page.hasMorePages === true ? 
                <button  onClick={(event) =>handleNextPage(page.currentPage)} className="bg-[#93b33a] rounded px-6 py-2 text-base text-white Metropolis-Bold mt-5">
                  Sigiente
                </button> 
                
                : ''
            }
              <BackToPage texto="Regresar" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
