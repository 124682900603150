import React from "react";
import { Register } from "../components/register/Register";
import {Routes, Route } from "react-router-dom";
import { Navbar } from "../components/ui/Navbar";
import { SideBarr } from "../components/ui/SideBarr";


export const RegisterRouter = () => {
  return (
    <div className="flex ">
      <SideBarr titulo={"Certificados"} />
      <div className={`h-screen w-full`}>
        <Navbar titulo={"Bienvenido"} />
        <Routes>
          <Route path="/" element={<Register />} />
        </Routes>
      </div>
    </div>
  );
};