import React from "react";
import { Routes, Route } from "react-router-dom";
import { Navbar } from "../components/ui/Navbar";
import { SideBarr } from "../components/ui/SideBarr";

import {ListImageStorage} from "../components/image-storage/ListImageStorage";
import {CreateImageStorage} from "../components/image-storage/CreateImageStorage";
import {ViewImageStorage} from "../components/image-storage/ViewImageStorage";
import {EditImageStorage} from "../components/image-storage/EditImageStorage";
import { permisos } from "../helper/permissions";

export const ImageStorageRouter = () => {
  const hasCrearPermission = permisos('Galeria', 'crear')
  return (
    <div className="flex "> 
      <SideBarr titulo={"Certificados"} />
      <div className={`h-screen w-full`}>
        <Navbar titulo={"Galería de Imagenes IU Digital"} nameModule={'Imagen'} phat={'/image-storage/create'} butonCreate={true} hasCrearPermission={hasCrearPermission}/>
        <Routes>
            <Route path="/" element={<ListImageStorage />} />
            <Route path="/create" element={<CreateImageStorage />} />
            <Route path="/view/:id" element={<ViewImageStorage />} />
            <Route path="/edit/:id" element={<EditImageStorage />} />
        </Routes>
      </div>
    </div>
  );
};
