import React from 'react'
import { BackToPage } from '../backToPage/BackToPage';
export const DetailComponent = (props) => {
    let bg = "#fff";
  return (
    <div className='mt-5 px-8'>
        {
            props.info !== null ? 
            <>
                <h1 className='text-3xl Metropolis-Extra-Bold color-titulos mb-4'>{ props.info.type }</h1>
            <div className='rounded bg-[#67b5dd] shadow-lg shadow-[#67b5dd] p-5'>
                <ul className=' my-5 rounded-lg border-[#243f59] border-4 w-full overflow-hidden text-white'>
                    {
                        props.info.content.map( (data, index) => {
                            if ( index %2 === 0){
                                bg = "#ececec";
                            } else {
                                bg = '#fff'
                            }
                            return(
                                <li 
                                    className={`px-6 py-2  w-full  color-titulos bg-[${bg}]`} 
                                    key={index}>
                                        <strong className='Metropolis-Bold'>{data.name}: </strong>
                                        <>
                                            {
                                                data.enlase 
                                                ?<a href={`${process.env.REACT_APP_IMAGES_URL}${data.value}`} target='_blank' rel='noopener noreferrer'>Link</a> 
                                                : <span className='Metropolis-Light'>{data.value}</span>
                                            }
                                    </>
                                    </li>
                            );
                        })
                    }
                    <li></li>
                </ul>
                <BackToPage texto="Volver"/>
            </div>
            </>
            : <h1>Cargando datos</h1>
        }
    </div>
  )
}
