import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { getPlantillasService } from "../../selectors/pantillasServices";
import { deleteTemplatesServices } from "../../selectors/pantillasServices";
import { permisos } from "../../helper/permissions";

export const ListTemplates = () => {

  const seccion = 'Plantillas'
  permisos(seccion, 'listar', 'alerta')
  const hasListarPermission = permisos(seccion, 'listar')
  const hasEditarPermission = permisos(seccion, 'editar')
  const hasVerPermission = permisos(seccion, 'ver')
  const hasBorrarPermission = permisos(seccion, 'borrar')
  const hasCrearPermission = permisos(seccion, 'crear')

  
  if (localStorage.getItem("plantilla")) {
    localStorage.removeItem("plantilla");
  }
  const [plantilla, setPlantillas] = useState([]);
  let classBg = "bg-[#fff]";
  useEffect(() => {
    getPlantillasService()
      .then((resp) => {
        if (resp.data.success) {
          localStorage.setItem("plantillas", JSON.stringify(resp.data.data));
          setPlantillas(resp.data.data);
        } else {
          if (resp.data.code == 401) {
            swal({
              title: "Alerta!!!",
              text: `${resp.data.data.message}`,
              icon: "warning",
              dangerMode: true,
            }).then(function () {
              window.location = "/bienvenido";
            });
          }
        }
      })
      .catch((error) => {
        swal({
          title: "Error",
          text: `Sucedió un error: ${error}`,
          icon: "error",
          dangerMode: true,
        });
      });
  }, []);

  const handleClick = (plantillaId) => {
    swal({
      title: "Alerta!!!",
      text: "¿Está seguro que desea eliminar el registro?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let nuevaListaDePlantillas = plantilla.filter(
          (plantilla) => plantilla.plantillaId !== plantillaId
        );
        deleteTemplatesServices(plantillaId)
          .then((resp) => {
            if (resp.data.success) {
              setPlantillas(nuevaListaDePlantillas);
              swal("plantilla Eliminada con Éxito", {
                icon: "success",
              });
            } else {
              if (resp.data.code == 401) {
                swal({
                  title: "Alerta!!!",
                  text: `${resp.data.data.message}`,
                  icon: "warning",
                  dangerMode: true,
                }).then(function () {
                  window.location = "/bienvenido";
                });
              } else {
                swal({
                  title: "Error",
                  text: resp.data.data[0],
                  icon: "error",
                  dangerMode: true,
                });
              }

            }
          })
          .catch((error) => {
            swal({
              title: "Error",
              text: `Sucedió un error: ${error}`,
              icon: "error",
              dangerMode: true,
            });
          });
      } else {
        swal("No se alteró la base de datos");
      }
    });
  };
  
  const copyToClipboard = (ParamTextImage) => {
  
  console.log(ParamTextImage);
  navigator.clipboard.writeText(ParamTextImage).then(() => {
    swal("Enlace Copiado", {
      icon: "success",
    });
  })
  .catch(() => {
    swal({
      title: "Error",
      text: `Sucedió un error`,
      icon: "error",
    });
  });
};


  return (
    <>
      {
        hasListarPermission && (
          <div className="flex flex-col px-5 mt-5">
            <div className="overflow-x-auto">
              <div className="p-5 inline-block min-w-full bg-[#67b5dd] rounded-[12px] mb-5 ">
                <div className="p-3 content-center">
                  <div className="overflow-hidden content-center">
                    <table className="min-w-full border text-center rounded-[12px] overflow-hidden">
                      <thead className="border-b bg-[#243f59] rounded-[12px]">
                        <tr>
                          <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                            Id
                          </th>
                          <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                            Nombre plantilla
                          </th>
                          <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                            Acciones
                          </th>
                        </tr>
                      </thead>
                      <tbody className="border-b bg-[#fff]">
                        {plantilla.map((plantilla, index) => {
                          if (index % 2 === 0) {
                            classBg = "bg-[#ececec]";
                          } else {
                            classBg = "bg-[#fff]";
                          }

                          return (
                            <tr
                              key={plantilla.plantillaId}
                              className={`border-b ${classBg}`}
                            >
                              <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r  w-1">
                                {plantilla.plantillaId}
                              </td>
                              <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r">
                                {plantilla.nombrePlantilla}
                              </td>
                              <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r bg-[#243f5926] w-44">
                                <div className="flex justify-center">
                                  <div>
                                    {hasEditarPermission && (
                                      <Link
                                        className="text-blue-600 visited:text-black-600"
                                        to={`./edit/${plantilla.plantillaId}`}
                                      >
                                        <img
                                          src={`/assets/icons/edit.png`}
                                          alt="Editar"
                                          title="Editar"
                                          className="w-7 cursor-pointer"
                                        />
                                      </Link>
                                    )
                                    }
                                  </div>&nbsp;
                                  <div>
                                    {hasVerPermission && (
                                      <Link
                                        className={
                                          "text-blue-600 visited:text-black-600"
                                        }
                                        to={`./view/${plantilla.plantillaId}`}
                                      >
                                        {" "}
                                        <img
                                          src={`/assets/icons/details.png`}
                                          alt="Ver Detalles"
                                          title="Ver Detalles"
                                          className="w-7 cursor-pointer"
                                        />
                                      </Link>
                                    )
                                    }
                                  </div>&nbsp;
                                  <div>
                                    <img
                                      onClick={(event) =>
                                        navigator.clipboard.writeText(
                                          `${process.env.REACT_APP_IMAGES_URL}${plantilla.imgCertificado}`
                                        ).then(() => {
                                          swal("Enlace Copiado", {
                                            icon: "success",
                                          });
                                        })
                                          .catch(() => {
                                            swal({
                                              title: "Error",
                                              text: `Sucedió un error`,
                                              icon: "error",
                                            });
                                          })
                                      }
                                      src={`/assets/icons/paste.png`}
                                      alt="Copiar URL"
                                      title="Copiar URL"
                                      className="w-7 cursor-pointer "
                                    />
                                  </div>&nbsp;
                                  <div>
                                    {hasBorrarPermission && (
                                      <img
                                        onClick={(event) =>
                                          handleClick(plantilla.plantillaId)
                                        }
                                        src={`/assets/icons/delete.png`}
                                        alt="Eliminar"
                                        title="Eliminar"
                                        className="w-7 cursor-pointer"
                                      />
                                    )
                                    }
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

    </>
  );
};
