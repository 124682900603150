import React from "react";
import {Routes, Route } from "react-router-dom";
import {BulkLoadStudentsAndCertificates}  from "../components/bulk-load/BulkLoadStudentsAndCertificates";
import { Navbar } from "../components/ui/Navbar";
import { SideBarr } from "../components/ui/SideBarr";
import { BulkLoadCursos } from "../components/bulk-load/BulkLoadCursos";
export const BulkLoadRouter = () => {
  return (
    <div className="flex "> 
          <SideBarr titulo={"Certificados"}/>
          <div className={`h-screen w-full`}>
          <Navbar titulo={"Carga Masiva"}/>
          <Routes>
          <Route path="student-certificates" element={<BulkLoadStudentsAndCertificates />}/>
          <Route path="cursos" element={<BulkLoadCursos />}/>
          </Routes>
        </div>
      </div>
  );
};