import React from "react";
import { Routes, Route } from "react-router-dom";
import { CreateStudents } from "../components/students/CreateStudents";
import { ViewStudents } from "../components/students/ViewStudents";
import { ListStudents } from "../components/students/ListStudents";
import { EditStudents } from "../components/students/EditStudents";
import { permisos } from "../helper/permissions";

import { Navbar } from "../components/ui/Navbar";
import { SideBarr } from "../components/ui/SideBarr";

export const StudentsRoutes = () => {
  const hasCrearPermission = permisos('Estudiantes', 'crear')

  return (
    <div className="flex "> 
      <SideBarr />
      <div className={`h-screen w-full`}>
        <Navbar titulo={"Estudiantes IU Digital"} nameModule={'Estudiante'} phat={'/students/create'} butonCreate={true}  hasCrearPermission={hasCrearPermission}/>
        <Routes>
          <Route path="/" element={<ListStudents />} />
          <Route path="" element={<ListStudents />} />
          <Route path="list" element={<ListStudents />} />
          <Route path="create" element={<CreateStudents />} />
          <Route path="edit/:id" element={<EditStudents />} />
          <Route path="view/:id" element={<ViewStudents />} />

        </Routes>
      </div>
    </div>
  );
};
