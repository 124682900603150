import React, { useState } from "react";
import swal from "sweetalert";
import { BulkLoadCursosServices } from "../../selectors/bulkLoad";
import { BackToPage } from "../generals/backToPage/BackToPage";
import "./BulkLoadStudentCertificates.css";

export const BulkLoadCursos = () => {

  const user = JSON.parse(localStorage.getItem('user'));

  const hasCursosPermission = user && user.permissions && user.permissions.Carga && user.permissions.Carga.includes('cursos');

  if (!hasCursosPermission) {
    swal({
      title: "Alerta!!!",
      text: `No estás autorizado para entrar en esta sección`,
      icon: "warning",
      dangerMode: true,
    }).then(function () {
      window.location = "/bienvenido";
    });

  }


  const [selectedFile, setselectedFile] = useState(null);
  const [errores, seterrores] = useState([]);
  const [showModal, setShowModal] = useState("hidden");
  let classBg = "bg-[#fff]";
  const onFileChange = (event) => {
    setselectedFile(event.target.files[0]);
  };

  const onFileUpload = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", selectedFile);
    BulkLoadCursosServices(formData)
      .then((result) => {
        if (result.data.success) {
          seterrores(result.data.data.error);
          swal(result.data.data.info_archivo, {
            icon: "success",
          });
        } else {
          swal({
            title: "Error",
            text: `Error: ${result.data.data.message.file[0]}`,
            icon: "error",
            dangerMode: true,
          });
        }
      })
      .catch((error) => {
        swal({
          title: "Error",
          text: `Sucedió un error: ${error}`,
          icon: "error",
          dangerMode: true,
        });
      });
  };

  return (
    <>
      {
        hasCursosPermission && (
          <div>
            <div className=" m-auto intrucciones lg:w-3/4">
              <div className="p-6 my-5 shadow-lg rounded-lg bg-gray-100 text-gray-700">
                <h2 className="font-semibold text-3xl mb-5 Metropolis-Extra-Bold">
                  Instrucciones previas
                </h2>
                <p className="Metropolis-Bold">
                  Siguiendo estos pasos podrás cargar sus datos de una mejor forma
                </p>
                <hr className="my-6 border-gray-300" />
                <ul>
                  <li className="my-3 py-5 border-b-4 border-indigo-500">
                    <span className="block Metropolis-Bold">
                      Paso 1: Descarga el siguiente archivo
                      <a
                        href="/assets/dowload/EstructuraCargaCursosCertificados.csv"
                        download="EstructuraCargaCursosCertificados.csv"
                        className="Metropolis-Bold float-right inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                        target="_self"
                      >
                        Archivo
                      </a>
                    </span>
                  </li>
                  <li className="my-3 py-5 border-b-4 border-indigo-500">
                    <span className="block Metropolis-Bold">
                      Paso 2: Sigue las siguientes Instrucciones
                      <button
                        type="button"
                        className="Metropolis-Bold float-right px-6 py-2.5 bg-blue-600  text-white  font-medium  text-xs  leading-tight  uppercase  rounded  shadow-md  hover:bg-blue-700 hover:shadow-lg  focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0  active:bg-blue-800 active:shadow-lg  transition  duration-150  ease-in-out"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() => setShowModal("show")}
                      >
                        Instrucciones
                      </button>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="mt-5">
              <h1 className="text-base lg:text-3xl my-5 text-center Metropolis-Extra-Bold">
                Carga Masiva de Cursos
              </h1>
              <div className="flex mt-3">
                <div className="lg:w-3/4  lg:m-auto px-5 py-5 rounded-2xl bg-[#67b5dd]">
                  <div className="flex">
                    <div className="d-sm-none lg:w-2/6 rounded-lg  max-full mx-2 bg-[#243f59] Metropolis-Bold text-2xl text-white leading-loose">
                      <ul>
                        <li className="ml-3">
                          <label>Cargar Archivo</label>
                        </li>
                      </ul>
                    </div>
                    <div className="lg:w-4/6 w-6/6">
                      <form onSubmit={onFileUpload}>
                        <div className="my-3">
                          <input
                            className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-[#67b5dd] bg-clip-padding border border-solid border-[#243f59] rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            type="file"
                            onChange={(e) => onFileChange(e)}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/comma-separated-values, text/csv, application/csv"
                          />
                        </div>
                        <input
                          className="bg-[#93b33a] rounded px-6 py-2 text-base text-white Metropolis-Bold"
                          type="submit"
                          value={"Guardar"}
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {errores.length > 0 ? (
                <div className="flex mt-3">
                  <div className="lg:w-3/4  lg:m-auto px-5 py-5 rounded-2xl bg-[#67b5dd]">
                    <h1 className="text-base lg:text-3xl my-5 Metropolis-Extra-Bold">
                      Errores
                    </h1>
                    <div className="lg:w-full rounded-lg  max-full mx-2 bg-[#243f59] Metropolis-Bold text-2xl text-white leading-loose">
                      <table className="min-w-full border  rounded-[12px] overflow-hidden">
                        <thead className="border-b bg-[#243f59] rounded-[12px]">
                          <tr>
                            <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                              Hoja
                            </th>
                            <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                              Mensajes
                            </th>
                          </tr>
                        </thead>

                        <tbody className="border-b ">
                          {errores.map((error, index) => {
                            if (index % 2 === 0) {
                              classBg = "bg-[#ececec]";
                            } else {
                              classBg = "bg-[#fff]";
                            }
                            return (
                              <tr className={`border-b ${classBg}`} key={index}>
                                <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r bg-[#243f5926]">
                                  {error.hoja}
                                </td>
                                <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r bg-[#243f5926]">
                                  <ul>
                                    {error.msg.map((mensaje, index) => {
                                      return <li key={index}>{mensaje}</li>;
                                    })}
                                  </ul>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {showModal === "show" ? (
              <div className={`modalInstrucciones`}>
                <div
                  className="modal fade mt-5 w-3/4 h-full outline-none overflow-x-hidden overflow-y-auto"
                  id="exampleModal"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog relative w-auto pointer-events-none">
                    <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                      <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                        <h5
                          className="text-xl font-medium leading-normal text-gray-800"
                          id="exampleModalLabel"
                        >
                          <strong>Instrucciones del archivo</strong>
                        </h5>
                      </div>
                      <div className="modal-body relative p-4">
                        <ul>
                          <li><strong>1.</strong> Todos los campos son obligatorios</li>
                          <li><strong>2.</strong> En el campo plantilla pon el ID que puedes encontrar <a target="_blank" href="/templates"><strong>aquí</strong></a></li>
                          <li><strong>3.</strong> si no tienes la calificación debes poner NULL</li>
                          <li><strong>4.</strong> Los campos de hora y mínima nota deben ser numéricos</li>
                          <li><strong>5.</strong> El formato de fecha debe ser "YYYY-MM-DD" <strong>
                            <br />NOTA: Todos los campos del archivo deben ser tipo texto </strong> </li>
                        </ul>
                      </div>
                      <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                        <h5
                          className="text-xl font-medium leading-normal text-gray-800"
                          id="exampleModalLabel"
                        >
                          <strong>Instrucciones de carga</strong>
                        </h5>
                      </div>
                      <div className="modal-body rel ative p-4">
                        <ul>
                          <li><strong>1.</strong> Da click en en el botón "<strong>Seleccionar archivo</strong>"</li>
                          <li><strong>2.</strong> Busca el archivo diligenciado en tu dispositivo</li>
                          <li><strong>3.</strong> Da click en en el botón "<strong>Guardar</strong>"</li>
                          <li><strong>4.</strong> Espera un momento y luego te saldrá los errores de carga en caso de haberlos
                            <br />NOTA: Cada línea te mostrará el campo a corregir y la línea del archivo a la cual te debes dirigir </li>
                        </ul>

                      </div>
                      <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                        <button
                          type="button"
                          className="px-6 py-2.5  bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                          data-bs-dismiss="modal"
                          onClick={() => setShowModal("hide")}
                        >
                          Cerrar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        )
      }
    </>
  );
};
export default BulkLoadCursos;
