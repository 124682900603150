import React from "react";
import { Routes, Route } from "react-router-dom";
import { ListRoles } from "../components/roles/ListRoles";
import { CreateRoles } from "../components/roles/CreateRoles";
import { EditRoles } from "../components/roles/EditRoles";

import { Navbar } from "../components/ui/Navbar";
import { SideBarr } from "../components/ui/SideBarr";
import { ViewRoles } from "../components/roles/ViewRoles";
import { permisos } from "../helper/permissions";
export const RolesRouter = () => {
  const hasCrearPermission = permisos('Roles', 'crear')
  return (
    <div className="flex "> 
          <SideBarr titulo={"Certificados"}/>
          <div className={`h-screen w-full`}>
          <Navbar titulo={"Roles Administrativos IU Digital"} nameModule={'Roles'} phat={'/roles/create'} butonCreate={true} hasCrearPermission={hasCrearPermission}/>
          <Routes>
            <Route path="/" element={<ListRoles />} />
            <Route path="" element={<ListRoles />} />
            <Route path="create" element={<CreateRoles />} />
            <Route path="edit/:id" element={<EditRoles />} />
            <Route path="view/:id" element={<ViewRoles />} />
          </Routes>
        </div>
      </div>
  );
};