import React, { useEffect, useState } from "react";
import certificado from "../../assets/img/certifies.png";
import logoescudo from "../../assets/img/logo-escudo.png";
import { getUpdatePermissionsService } from "../../selectors/getRoles";


export const Register = () => {

  useEffect(() => {
    getUpdatePermissionsService().then((resp) => {
      if (resp.data.success) {
        const user = JSON.parse(localStorage.getItem('user'));
        user.permissionsORG = user.permissions;
        user.permissions = resp.data.data
        localStorage.removeItem('user');
        localStorage.setItem('user', JSON.stringify(user));

        const sonDiferentes = JSON.stringify(user.permissionsORG) !== JSON.stringify(user.permissions);

        sonDiferentes ? window.location.reload() : console.log('Los objetos JSON son iguales');
  
      }
      console.log(resp);
    })
  }, []);



  return (
    <div className='mt-5 px-8'>
      <div className='rounded bg-[#67b5dd] shadow-lg shadow-[#67b5dd] p-5'>
        <div className='rounded bg-[#67b5dd] shadow-lg shadow-[black] p-5'>
          <div className=" Metropolis-Extra-Bold text-center text-black text-2xl font-bold">BIENVENIDO A CERTIFICADOS </div>
          <img className="w-64 mx-auto py-9" src={certificado} />
          <img className="w-52 mx-auto py-9" src={logoescudo} />
        </div>
      </div>
    </div>
  )
}
