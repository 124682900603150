import React from "react";
import logoescudo from "../../assets/img/logo-escudo.png";
import loginin from "../../assets/img/login.png";
import loginwhite from "../../assets/img/logowhite.png";
import { useForm } from "../../hooks/useForm";
import { LoginService } from "../../selectors/login";
export const Login = () => {
  localStorage.clear();
  const [formValues, handleInputChange] = useForm({
    email: "",
    password: "",
  });
  const { email, password } = formValues;

  const handleLogin = async (e) => {
    e.preventDefault();
    await LoginService(formValues).then((resp) => {
      console.log(resp)
    });
  };

  return (
    <>
      <div id="midiv"
        style={{backgroundImage: `url(${loginin})`}}
        className=" bg-cover bg-no-repeat  h-screen ml-96"><img className="w-64 mx-12 py-9 absolute bottom-0 left-3/4" src={loginwhite}/>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-6 gap-x-4">
        <div className="col-span-6 sm:col-span-6 md:col-span-1">
          <div className="Containerlogin-box absolute bottom-0 h-screen w-100 py-0 m-0 bg-slate-50">
            <form onSubmit={handleLogin}>
              <div className="mb-3 my-44">
                <img className="w-64 mx-12 py-9" src={logoescudo}/>
                <input 
                  type="text"
                  placeholder="E-MAIL"
                  className="form-control text-3x1 font-bold mx-10 border rounded-full w-100 py-4 px-8 text-center text-gray-700 leading-tight bg-gray-200"
                  name="email"
                  autoComplete="off"
                  value={email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <input
                  type="password"
                  placeholder="CONTRASEÑA"
                  className="form-control text-3x1 font-bold mx-10 border rounded-full w-100 py-4 px-8 text-center text-gray-700 leading-tight bg-gray-200"
                  name="password"
                  autoComplete="off"
                  value={password}
                  onChange={handleInputChange}
                />
              </div>
              <input
              className="text-xl font-bold text-center mx-32 border rounded-full w-70 py-3 px-5 text-white leading-tight bg-[#93b33a]" 
              type="submit" 
              value={"ENTRAR"}/>
            </form>
          </div> 
        </div>
      </div>
    </>                  
  );
}
