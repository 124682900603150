import React from 'react'
import { Routes, Route, BrowserRouter } from "react-router-dom";

/* Rutar publicas*/
import {Login} from '../components/login/Login';

/*Rutar privadas*/
import { BulkLoadRouter } from './BulkLoadRouter';
import { CertificateRoutes } from './CertificateRoutes';
import { StudentsRoutes } from './StudentsRoutes';
import { UsersRouter } from './UsersRouter';
import { TemplatesRoutes } from './TemplatesRoutes';
import { ImageStorageRouter } from './ImageStorageRouter';
import { RolesRouter } from './RolesRouter';
import { RegisterRouter } from './RegisterRouter';
import { SyncRoutes } from './SyncRoutes';

export const AppRouter = () => {
  return (
      <BrowserRouter>
        <Routes>
            <Route path="/login" element={ <Login /> } />
            <Route path="/" element={ <Login /> } />

            {/* Rutas privadas */}
            <Route path="/certificate/*" element={ <CertificateRoutes /> } />
            <Route path="/users/*" element={<UsersRouter />}/>
            <Route path="/students/*" element={<StudentsRoutes />}/>

            <Route path="/bulk-load/*" element={<BulkLoadRouter />}/>

            <Route path="/templates/*" element={<TemplatesRoutes />}/>
            <Route path="/image-storage/*" element={<ImageStorageRouter />}/>
            <Route path="/roles/*" element={<RolesRouter />}/>
            <Route path="/bienvenido/*" element={ <RegisterRouter /> } />
            <Route path="/sincronizacion" element={ <SyncRoutes /> } />

        </Routes>
      </BrowserRouter>
  )
}
