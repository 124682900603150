import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { getPermissionsService, editRoleService, getRoleService } from "../../selectors/getRoles";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import { BackToPage } from "../generals/backToPage/BackToPage";
import "./Role.css";
import { permisos } from "../../helper/permissions";

export const EditRoles = () => {
  const seccion = 'Roles'
  permisos(seccion, 'listar', 'alerta')
  const hasListarPermission = permisos(seccion, 'listar')
  const hasEditarPermission = permisos(seccion, 'editar')
  const hasVerPermission = permisos(seccion, 'ver')
  const hasBorrarPermission = permisos(seccion, 'borrar')
  const hasCrearPermission = permisos(seccion, 'crear')


  const params = useParams();
  const navigate = useNavigate();
  let sectionPermission;
  const [camposInvalidosForm, setCamposInvalidosForm] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [role, setRole] = useState({
    id: "",
    name: "",
    description: "",
  });
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    description: "",
    permissions: [],
  });
  const [aux_permisos, setAuxPermisos] = useState([]);
  const estilo =
    "Metropolis-Bold placeholder:text-slate-400 block bg-white w-full rounded-md mx-1 py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm";


  useEffect(() => {

    getRoleService(params.id).then((resp) => {
      console.log('no se');
      console.log(resp.data.data);
      if (resp.data.success) {
        const permisos = resp.data.data.permissions;
        const permisosIds = permisos.map((permiso) => permiso.id);
        setAuxPermisos(permisosIds);



        setRole({
          id: resp.data.data.id,
          name: resp.data.data.name,
          description: resp.data.data.description,
        });

      }
    });

    getPermissionsService()
      .then((resp) => {
        console.log(resp.data.data);
        if (resp.data.success) {
          setPermissions(resp.data.data);
        }
      })
  }, []);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      id: role.id,
      name: role.name,
      description: role.description,
      permissions: aux_permisos,
    }));
  }, [role]);





  const { id, name, description, permisos: selectedPermissions } = formData;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };


  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      permissions: checked
        ? [...prevFormData.permissions, Number(value)]
        : prevFormData.permissions.filter((item) => item !== Number(value)),
    }));
    if (checked) {
      setAuxPermisos([...aux_permisos, Number(value)]);
    } else {
      setAuxPermisos(aux_permisos.filter((permiso) => permiso !== Number(value)));

    }


  };




  const handleEdit = (event) => {
    event.preventDefault();
    let camposInvalidos = obtenerCamposInvalidosDelForm(formData);
    if (camposInvalidos.length > 0) {
      setCamposInvalidosForm(camposInvalidos);
    } else {
      console.log(formData);
      editRoleService(formData).then((resp) => {
        if (resp.data.success) {
          swal(
            `Rol ${formData.name} fue creado con Éxito`,
            {
              icon: "success",
            }
          );
          navigate("../");
        } else {
          if (resp.data.code == 401) {
            swal({
              title: "Alerta!!!",
              text: `${resp.data.data.message}`,
              icon: "warning",
              dangerMode: true,
            }).then(function () {
              window.location = "/bienvenido";
            });
          } else {
            swal({
              title: "Error",
              text: resp.data.data[0],
              icon: "error",
              dangerMode: true,
            });
          }


        }
      });
    }


  };

  const obtenerCamposInvalidosDelForm = (formData) => {

    let camposInvalidos = [];
    if (formData.name === "") {
      camposInvalidos.push("name");
    }
    if (formData.permissions === "") {
      camposInvalidos.push("permissions");
    }
    return camposInvalidos;
  };

  return (
    <>
      {camposInvalidosForm.includes(
        "name",
        "permissions",
      ) && (
          <span className="flex bg-[#FBE9EA] px-6 py-2 mt-5 ml-10 mr-10 border-l-4 border-l-rose-600 text-base text-[#4d4d4d] Metropolis">
            <b>Advertencia:</b>Los campos marcados en Rojo son Obligatorios
          </span>
        )}

      {
        hasEditarPermission && (
          <div className="mt-3">
            <h1 className="text-base lg:text-3xl my-5 text-center Metropolis-Extra-Bold">
              Crear Rol
            </h1>
            <div className="flex mt-3">
              <div className="lg:w-3/4  lg:m-auto px-5 py-5 rounded-2xl bg-[#67b5dd]">
                <div className="flex">
                  <div className="d-sm-none lg:w-2/6 rounded-lg  max-full mx-2 bg-[#243f59] Metropolis-Bold text-2xl text-white leading-loose">
                    <ul>
                      <li className="pl-3">
                        <label>Nombre</label>
                      </li>
                      <li className="pl-3">
                        <label>Descripción</label>
                      </li>
                      <li className="pl-3">
                        <label>Servicios</label>
                      </li>
                    </ul>
                  </div>
                  <div className="lg:w-4/6 w-6/6">
                    <form onSubmit={handleEdit}>
                      <input type="hidden" name="id" value={id} />
                      <div className="mb-3 ">
                        <input
                          type="text"
                          placeholder="Nombre del Rol"
                          className={
                            camposInvalidosForm.includes("name")
                              ? estilo + " border-2 border-rose-600"
                              : estilo
                          }
                          name="name"
                          autoComplete="off"
                          value={name}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3 ">
                        <input
                          type="text"
                          placeholder="¿Qué función tien este rol?"
                          className={
                            camposInvalidosForm.includes("description")
                              ? estilo + " border-2 border-rose-600"
                              : estilo
                          }
                          name="description"
                          autoComplete="off"
                          value={description}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3 permisosCheck">
                        {
                          Object.keys(permissions).map((e) => (
                            <div key={e}>
                              <strong>{e}</strong>
                              <ul>
                                {permissions[e].map((permission) => (
                                  <li key={permission}>
                                    <input type="checkbox" id={permission[1]} name='permissions'
                                      value={permission[1]} onChange={handleCheckboxChange}
                                      checked={aux_permisos.includes(permission[1])}
                                    />
                                    <label htmlFor={permission[1]}>{permission[0]}</label>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))
                        }

                      </div>
                      <input
                        className="bg-[#93b33a] rounded px-6 py-2 text-base text-white Metropolis-Bold"
                        type="submit"
                        value={"Guardar"}
                      />
                      <BackToPage texto="Cancelar" />
                    </form>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}


    </>
  );
};
