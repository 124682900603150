import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useForm } from "../../hooks/useForm";
import { useNavigate, useParams } from "react-router-dom";
import { editUserService } from "../../selectors/getUsers";
import { BackToPage } from "../generals/backToPage/BackToPage";
import { getRolesService } from "../../selectors/getCertificates";
import { permisos } from "../../helper/permissions";

export const EditUsers = () => {

  const seccion = 'Usuarios'
  permisos(seccion, 'listar', 'alerta')
  const hasListarPermission = permisos(seccion, 'listar')
  const hasEditarPermission = permisos(seccion, 'editar')
  const hasVerPermission = permisos(seccion, 'ver')
  const hasBorrarPermission = permisos(seccion, 'borrar')
  const hasCrearPermission = permisos(seccion, 'crear')
  
  const [roles, setRoles] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
  const [camposInvalidosForm, setCamposInvalidosForm] = useState([]);
  const cargaData = () => {
    const data = JSON.parse(localStorage.getItem("users"));
    let usuario = {};
    const usersById = data['data'];
    usuario = usersById.find((user) => user.id === parseFloat(params.id));
    return usuario;
  };

  const estilo =
    "Metropolis-Bold placeholder:text-slate-400 block bg-white w-full rounded-md mx-1 py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm";

  const defaultValues = cargaData();
  const [formValues, handleInputChange] = useForm({
    id: defaultValues.id,
    name: defaultValues.name,
    lastname: defaultValues.lastname,
    email: defaultValues.email,
    password: defaultValues.password,
    role_id: defaultValues.role_id
  });

  const { name, lastname, email, password, role_id } = formValues;

  const handleEdit = (e) => {
    e.preventDefault();
    let camposInvalidos = [];
    camposInvalidos = obtenerCamposInvalidosDelForm(formValues);
    if (camposInvalidos.length === 0) {
      editUserService(formValues)
        .then((resp) => {
          if (resp.data.success) {
            swal("Usuario editado con Éxito", {
              icon: "success",
            });
            navigate("../");
          } else {
            if (resp.data.code == 401) {
              swal({
                title: "Alerta!!!",
                text: `${resp.data.data.message}`,
                icon: "warning",
                dangerMode: true,
              }).then(function () {
                window.location = "/bienvenido";
              });
            } else {
              swal({
                title: "Error",
                text: `Error: ${JSON.stringify(resp.data.data.message)}`,
                icon: "error",
                dangerMode: true,
              });
            }

          }
        })
        .catch((error) => {
          swal({
            title: "Error",
            text: `Sucedió un error: ${error}`,
            icon: "error",
            dangerMode: true,
          });
        });
    } else {
      setCamposInvalidosForm(camposInvalidos);
    }
  };

  const obtenerCamposInvalidosDelForm = (formValues) => {
    let camposInvalidos = [];
    if (formValues.name === "") {
      camposInvalidos.push("name");
    }
    if (formValues.lastname === "") {
      camposInvalidos.push("lastname");
    }
    if (formValues.email === "") {
      camposInvalidos.push("email");
    }
    if (formValues.password === "") {
      camposInvalidos.push("password");
    }
    return camposInvalidos;
  };

  useEffect(() => {
    getRolesService()
      .then((resp) => {
        if (resp.data.success) {
          setRoles(resp.data.data);
        }
      })
  }, []);

  const handleOnBlur = (e) => {
    /*  e.preventDefault();*/
      let camposInvalidos = obtenerCamposInvalidosDelForm(formValues);
      console.log("Campos Invalidos: " + JSON.stringify(camposInvalidos));
      if (camposInvalidos.length > 0) {
        setCamposInvalidosForm(camposInvalidos);
      }
    }

  return (
    <>
      {camposInvalidosForm.includes(
        "name",
        "lastname",
        "email",
        "password",
        "role_id"
      ) && (
          <span className="flex bg-[#FBE9EA] px-6 py-2 mt-5 ml-10 mr-10 border-l-4 border-l-rose-600 text-base text-[#4d4d4d] Metropolis">
            <b>Advertencia:</b>&nbsp;Los campos marcados en Rojo son Obligatorios
          </span>
        )}
      {
        hasEditarPermission && (
          <div className="mt-3">
            <h1 className="text-center text-base lg:text-3xl my-5 Metropolis-Extra-Bold">
              Editar Usuario
            </h1>
            <div className="flex mt-3">
              <div className="w-3/4 m-auto px-5 py-5 rounded-2xl bg-[#67b5dd]">
                <div className="flex">
                  <div className="w-2/6 rounded-lg  max-full mx-2 bg-[#243f59] Metropolis-Bold text-2xl text-white leading-loose">
                    <ul>
                      <li className="pl-3">
                        <label>Nombre</label>
                      </li>
                      <li className="pl-3">
                        <label>Apellido</label>
                      </li>
                      <li className="pl-3">
                        <label>Rol</label>
                      </li>
                      <li className="pl-3">
                        <label>Email</label>
                      </li>
                      <li className="pl-3">
                        <label title="Se sugiere agregar caracteres alfanumericos y simbolos de manera mezclada">Contraseña 🛈</label>
                      </li>
                    </ul>
                  </div>
                  <div className="w-4/6">
                    {defaultValues !== {} ? (
                      <>

                        <form onSubmit={handleEdit}>
                          <div className="mb-3 ">
                            <input
                              type="text"
                              placeholder="Nombres"
                              className={
                                camposInvalidosForm.includes("name")
                                  ? estilo + " border-2 border-rose-600"
                                  : estilo
                              }
                              required
                              name="name"
                              autoComplete="off"
                              value={name}
                              onChange={handleInputChange}
                              onBlur={handleOnBlur}
                            />
                          </div>
                          <div className="mb-3">
                            <input
                              type="text"
                              placeholder="Apellidos"
                              className={
                                camposInvalidosForm.includes("lastname")
                                  ? estilo + " border-2 border-rose-600"
                                  : estilo
                              }
                              required
                              name="lastname"
                              autoComplete="off"
                              value={lastname}
                              onChange={handleInputChange}
                              onBlur={handleOnBlur}
                            />
                          </div>
                          <div className="mb-3">
                            <select
                              className={
                                camposInvalidosForm.includes("role_id")
                                  ? estilo + " border-2 border-rose-600"
                                  : estilo
                              }
                              autoComplete="off"
                              name="role_id"
                              value={role_id}
                              onChange={handleInputChange}
                              onBlur={handleOnBlur}
                            >
                              <option value="">Seleccione Rol</option>
                              {roles.map((rol, index) => {
                                return (
                                  <option key={index} value={rol.id}>
                                    {rol.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="mb-3">
                            <input
                              type="email"
                              placeholder="E-mail"
                              className={
                                camposInvalidosForm.includes("email")
                                  ? estilo + " border-2 border-rose-600"
                                  : estilo
                              }
                              required
                              name="email"
                              pattern='([\w\-\.]+@[\w\-]+[\.{1}]\w{2,}$|[\w\-\.]+@[\w\-]+[\.{1}]\w{2,3}+[\.{1}]\w{2})'
                              autoComplete="off"
                              value={email}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="mb-3">
                            <input
                              type="password"
                              placeholder="Contraseña * (6 o + Caracteres)"
                              className={
                                camposInvalidosForm.includes("password")
                                  ? estilo + " border-2 border-rose-600"
                                  : estilo
                              }
                              title="Se sugiere agregar caracteres alfanumericos y simbolos de manera mezclada"
                              name="password"
                              pattern=".{6,}"
                              autoComplete="off"
                              value={password}
                              onChange={handleInputChange}
                              onBlur={handleOnBlur}
                            />
                          </div>
                          <input
                            className="bg-[#93b33a] rounded px-6 py-2 text-base text-white Metropolis-Bold"
                            type="submit"
                            value={"Guardar"}
                          />
                          <BackToPage texto="Cancelar" />
                        </form>
                      </>
                    ) : (
                      <p>Cargando...</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

    </>
  );
};
