import axios from 'axios';
let token = '';
if (localStorage.getItem('user')) {
    token = JSON.parse(localStorage.getItem('user'))['api_token'];
}
export const getAllImagesStorageService = (page = 1, txt = '') => {
    if (txt !== "") {
        return axios.get(`${process.env.REACT_APP_API_URL}/banco?page=${page}&buscador=${txt}&api_token=${token}`);
    } else {
        return axios.get(`${process.env.REACT_APP_API_URL}/banco?page=${page}&api_token=${token}`)
    }

}
export const getAllImagesStorageNotPaginateService = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/repositorio/listar?api_token=${token}`)
}

export const getSingelImageStorageService = (id) => {
    if (id === '') {
        throw new Error('Invalid certificate');
    }
    return axios.get(`${process.env.REACT_APP_API_URL}/repositorio/picture/${id}?api_token=${token}`)
}

export const createImageStorageService = (data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/repositorio/create?api_token=${token}`, data);
}

export const editImageStorageService = (id, data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/repositorio/update/${id}?api_token=${token}`, data)
}

export const deleteImageStorageService = (id) => {
    if (id === '') {
        throw new Error('Invalid certificate');
    }
    return axios.delete(`${process.env.REACT_APP_API_URL}/repositorio/delete/${id}?api_token=${token}`)
}