import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom';

export const Navbar = (props) => {
  const navigate = useNavigate();
  const handleLogOut = () => {
    localStorage.removeItem('user');
    navigate('../login')
  }
  const user = JSON.parse(localStorage.getItem('user'));
  useEffect(() => {
    if (user === null){
      navigate('../login');
    }
  },[])
  const handelNavigate = (path) => {
    navigate(path)
  }
  
  return (
    <div className='px-8 py-5 w-full bg-[#ececec] rounded-bl-lg'>
      <h1>{props.titulo}</h1>      
      <h6>{user.name} {user.lastname}</h6>
      {props.butonCreate ?
      
        (props.hasCrearPermission)?
          <button
          onClick={() =>handelNavigate(props.phat)}
            className="absolute right-24 top-3 py-2.5 bg-[#93b33a] text-white Metropolis-Bold text-xs leading-tight uppercase rounded-md shadow-md hover:bg-[#93b33a] hover:shadow-lg focus:bg-[#93b33a] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#93b33a] active:shadow-lg transition  pl-3 pr-3 duration-150 ease-in-out"
            >Crear {props.nameModule}</button>
          :''
   
        :''}
        
      <button 
        onClick={handleLogOut}
        type="button" 
        className="absolute right-3 top-3 inline-block px-6 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out">Salir</button>
    </div>
  )
}

