import axios from 'axios';
/**
 * http://localhost/certificate-backend/public/api'
 * @returns en la url mandar ?api_token=token
 */
let token = ''
if (localStorage.getItem('user')) {
    token = JSON.parse(localStorage.getItem('user'))['api_token'];

    let user = JSON.parse(localStorage.getItem('user'));
    user.permisos = {'permisos':{'cursos':{'crear':'crear','actualizar':'actualizar'}}};
    localStorage.setItem('user', JSON.stringify(user));

    
    console.log(localStorage.getItem('user'));
}
export const getAllCertificatesService = (page = 1, txt = '', sync = '') => {

    if (sync !== "" && txt !== "") {
        return axios.get(`${process.env.REACT_APP_API_URL}/cursos?page=${page}&buscador=${txt}&sincronizar=${sync}&api_token=${token}`);
    } else if (txt !== "" && sync === "") {
        return axios.get(`${process.env.REACT_APP_API_URL}/cursos?page=${page}&buscador=${txt}&api_token=${token}`);
    } else if (sync !== "" && txt === "") {
        return axios.get(`${process.env.REACT_APP_API_URL}/cursos?page=${page}&sincronizar=${sync}&api_token=${token}`);
    } else {
        return axios.get(`${process.env.REACT_APP_API_URL}/cursos?page=${page}&api_token=${token}`);
    }
}

export const getCertificateByIdService = (id) => {
    if (id === '') {
        throw new Error('Invalid certificate');
    }
    return axios.get(`${process.env.REACT_APP_API_URL}/curso/${id}?api_token=${token}`);
}

export const createCertificates = (data) => {

    return axios.post(`${process.env.REACT_APP_API_URL}/curso/create?api_token=${token}`, data);

}

export const editCertificatesServices = (data) => {
    if (data !== undefined) {
        return axios.put(`${process.env.REACT_APP_API_URL}/curso/update/${data.cursoId}?api_token=${token}`, data)
    } else {
        throw new Error('Invalid certificate');
    }
}

export const deleteCertificatesServices = (id) => {
    if (id === '') {
        throw new Error('Invalid certificate');
    }
    return axios.delete(`${process.env.REACT_APP_API_URL}/curso/delete/${id}?api_token=${token}`);
}

export const VisualizationOfStudentsByCourseService = (id, page = 1) => {
    if (id === '') {
        throw new Error('Es necesario el id para poder acceder a los estudiantes asociados');
    }
    return axios.get(`${process.env.REACT_APP_API_URL}/estudiantes_certificado/${id}?page=${page}&api_token=${token}`);
}

export const getIstanceService = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/instancias?api_token=${token}`);
}

export const getAreasService = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/areas?api_token=${token}`);
}

export const getAllCertificatesStudentsService = (page = 1, txt = '') => {

    var sendParams = "";
    if (txt !== "") {
        sendParams+= `&buscador=${txt}`;
    }
 
    return axios.get(`${process.env.REACT_APP_API_URL}/certificados/listar?page=${page}${sendParams}&api_token=${token}`);
 
}



export const getRolesService = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/roles/listar?api_token=${token}`);
}
