import React from "react";
import { Routes, Route } from "react-router-dom";
import { CreateUsers } from "../components/users/CreateUsers";
import { ListUsers } from "../components/users/ListUsers";
import { EditUsers } from "../components/users/EditUsers";
import { Navbar } from "../components/ui/Navbar";
import { SideBarr } from "../components/ui/SideBarr";
import { ViewUsers } from "../components/users/ViewUsers";
import { permisos } from "../helper/permissions";
export const UsersRouter = () => {
  const hasCrearPermission = permisos('Usuarios', 'crear')
  return (
    <div className="flex "> 
          <SideBarr titulo={"Certificados"}/>
          <div className={`h-screen w-full`}>
          <Navbar titulo={"Usuarios Administrativos IU Digital"} nameModule={'Usuario'} phat={'/users/create'} butonCreate={true} hasCrearPermission={hasCrearPermission}/>
          <Routes>
            <Route path="/" element={<ListUsers />} />
            <Route path="" element={<ListUsers />} />
            <Route path="create" element={<CreateUsers />} />
            <Route path="edit/:id" element={<EditUsers />} />
            <Route path="view/:id" element={<ViewUsers />} />
          </Routes>
        </div>
      </div>
  );
};