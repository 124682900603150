import axios from "axios";
let token = '';
if (localStorage.getItem('user')) {
    token = JSON.parse(localStorage.getItem('user'))['api_token'];
}

export const getPlantillasService = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/plantillas?api_token=${token}`)
}

export const getPlantillasByIdService = (id) => {

    if (id === '') {
        throw new Error('El Id ingresado es invalido');
    }

    return axios.get(`${process.env.REACT_APP_API_URL}/plantilla/${id}?api_token=${token}`);
}

export const createTemplatesService = (data) => {

    return axios.post(`${process.env.REACT_APP_API_URL}/plantilla/create?api_token=${token}`, data);
}

export const editTemplatesService = (id,formData) => {   
    return axios.post(`${process.env.REACT_APP_API_URL}/plantilla/update/${id}?api_token=${token}`,formData);
}

export const deleteTemplatesServices = (id) => {
    if (id === '') {
        throw new Error('Invalid Template');
    }
    return axios.delete(`${process.env.REACT_APP_API_URL}/plantilla/delete/${id}?api_token=${token}`);
}


export const getPreviewPDFService = (id,formData) => {   
    return axios.post(`${process.env.REACT_APP_PUBLIC_CERTIFICATES_URL}/pdf/preview/${id}`, formData, {
        responseType: 'blob' // Configura el tipo de respuesta como blob
    });
}