import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { useParams } from "react-router-dom";
import { getAreasService, getCertificateByIdService, getIstanceService } from "../../selectors/getCertificates";
import { DetailComponent } from "../generals/detailComponent/DetailComponent";
import { permisos } from "../../helper/permissions";

export const ViewCertificates = () => {

  const seccion = 'Cursos'
  permisos(seccion, 'listar', 'alerta')
  const hasListarPermission = permisos(seccion, 'listar')
  const hasEditarPermission = permisos(seccion, 'editar')
  const hasVerPermission = permisos(seccion, 'ver')
  const hasBorrarPermission = permisos(seccion, 'borrar')
  const hasCrearPermission = permisos(seccion, 'crear')


  const [defaultValues, setDefaultValues] = useState({})
  const params = useParams();
  const [instancias, setInstancias] = useState([]);
  const [areas, setAreas] = useState([]);

  useEffect(() => {
    getIstanceService().then((data) => {
      if (data.data.success) {
        setInstancias(data.data.data);
      }
    }).catch((error) => {
      swal({
        title: "Error",
        text: `Sucedió un error: ${error}`,
        icon: "error",
        dangerMode: true,
      });
    });
    getAreasService().then((data) => {
      if (data.data.success) {
        setAreas(data.data.data)
      }
    }).catch((error) => {
      swal({
        title: "Error",
        text: `Sucedió un error: ${error}`,
        icon: "error",
        dangerMode: true,
      });
    });
    getCertificateByIdService(params.id).then(resp => {
      if (resp.data.success) {
        const result = { ...defaultValues, ...resp.data.data };
        setDefaultValues(result)
      }
    }).catch((error) => {
      swal({
        title: "Error",
        text: `Sucedió un error: ${error}`,
        icon: "error",
        dangerMode: true,
      });
    });
  }, [])
  instancias.map(instancia => {
    if (instancia.id === defaultValues.instancia_id) {
      defaultValues.instancia_id = instancia.name;
    }
  });
  areas.map(area => {
    if (area.id === defaultValues.area_id) {
      defaultValues.area_id = area.name;
    }
  });

  const data = {
    type: "Cursos",
    content: [
      {
        name: "Código Curso",
        value: defaultValues.codigoCurso,
      },
      {
        name: "Nombre Curso ",
        value: `${defaultValues.nombreCurso} ${defaultValues.nombreCurso2}`,
      },
      {
        name: "Nombre Plantilla",
        value: defaultValues.nombrePlantilla,
      },
      {
        name: "Nota Minima",
        value: defaultValues.minima_nota,
      },
      {
        name: "Horas",
        value: defaultValues.horas,
      },
      {
        name: "Fecha de certificación ",
        value: defaultValues.fechaCertificacion,
      },
      {
        name: "Texto 1",
        value: defaultValues.texto1,
      },
      {
        name: "logos",
        value: defaultValues.texto2,
        enlase: (defaultValues.texto2) ? true : false
      },
      {
        name: "Firmas",
        value: defaultValues.texto3,
        enlase: (defaultValues.texto3) ? true : false
      },
      {
        name: "Encuesta",
        value: defaultValues.url_encuesta,
      },
      {
        name: "Sincronización",
        value: defaultValues.sincronizar,
      },
      {
        name: "Área",
        value: defaultValues.area_id,
      },
      {
        name: "Instancia",
        value: defaultValues.instancia_id,
      },
    ],
  };
  return (
    <>
      <DetailComponent info={data} />
    </>
  );
};
