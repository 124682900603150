import axios from 'axios';
/**
 * http://localhost/certificate-backend/public/api'
 * @returns en la url mandar ?api_token=token
 */
let token = ''
let role_id = ''
if (localStorage.getItem('user')) {
    role_id = JSON.parse(localStorage.getItem('user'))['role_id'];
    token = JSON.parse(localStorage.getItem('user'))['api_token'];
}

export const getRolesService = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/roles?api_token=${token}`);
}

export const getPermissionsService = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/permisos?api_token=${token}`);
}

 
export const createRoleService = (data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/roles/create?api_token=${token}`, data);
}

export const editRoleService = (data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/roles/update/${data.id}?api_token=${token}`, data);
}

export const getRoleService = (role_id) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/roles/get_role/${role_id}?api_token=${token}`);
}

export const deleteRoleService = (id) => {
    if (id === '') {
        throw new Error('Invalid Role');
    }
    return axios.delete(`${process.env.REACT_APP_API_URL}/roles/delete/${id}?api_token=${token}`);
}

export const getUpdatePermissionsService = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/roles/permisos/${role_id}?api_token=${token}`);
}
