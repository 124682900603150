import axios from 'axios';

let token = ''
if (localStorage.getItem('user')) {
    token = JSON.parse(localStorage.getItem('user'))['api_token'];
}

export const getAllStudentsService = (page = 1, txt = "") => {
    if (txt !== "") {
        return axios.get(`${process.env.REACT_APP_API_URL}/estudiantes?page=${page}&buscador=${txt}&api_token=${token}`);
    } else {
        return axios.get(`${process.env.REACT_APP_API_URL}/estudiantes?page=${page}&api_token=${token}`);
    }

}

export const getStudentByIdService = (id) => {
    if (id === '') {
        throw new Error('La ID de estudiante es invalida');
    }
    return axios.get(`${process.env.REACT_APP_API_URL}/estudiante/${id}?api_token=${token}`);
}

export const getStudentByIdToEditService = (id) => {
    if (id === '') {
        throw new Error('La ID de estudiante es invalida');
    }
    return axios.get(`${process.env.REACT_APP_API_URL}/student/${id}?api_token=${token}`);
}

export const editStudentServices = (data) => {
    if (data !== undefined) {
        return axios.put(`${process.env.REACT_APP_API_URL}/estudiante/update/${data.userid}?api_token=${token}`, data)
    } else {
        throw new Error('Invalid student');
    }
}

export const createStudentsService = (data) => {

    return axios.post(`${process.env.REACT_APP_API_URL}/estudiante/create?api_token=${token}`, data);

}

export const deleteStudentsService = (id) => {
    if (id === '') {
        throw new Error('Invalid Student');
    }
    return axios.delete(`${process.env.REACT_APP_API_URL}/estudiante/delete/${id}?api_token=${token}`);
}


export const deleteCertificatesService = (id) => {
    if (id === '') {
        throw new Error('Invalid Certificate');
    }
    return axios.delete(`${process.env.REACT_APP_API_URL}/estudiante/certificado/delete/${id}?api_token=${token}`);
}



export const createCertificateService = (data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/estudiante/certificado/add?api_token=${token}`, data);
}

export const getCertificatesService = (id) => {

    return axios.get(`${process.env.REACT_APP_API_URL}/certificados_estudiante/${id}?api_token=${token}`);

}

export const editEstudentByCertificateService = (data) => {
    if (data !== undefined) {
        return axios.post(`${process.env.REACT_APP_API_URL}/estudiante/certificado/update/${data.id}?api_token=${token}`, data)
    } else {
        throw new Error('Invalid Certificate');
    }
}