import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import { createStudentsService } from "../../selectors/getStudents";
import { getIDTypeService } from "../../selectors/idtypeServices";
import { BackToPage } from "../generals/backToPage/BackToPage";
import "./Students.css";
import { permisos } from "../../helper/permissions";

export const CreateStudents = () => {
  const seccion = 'Estudiantes'
  permisos(seccion, 'listar', 'alerta')
  const hasListarPermission = permisos(seccion, 'listar')
  const hasEditarPermission = permisos(seccion, 'editar')
  const hasVerPermission = permisos(seccion, 'ver')
  const hasBorrarPermission = permisos(seccion, 'borrar')
  const hasCrearPermission = permisos(seccion, 'crear')




  const [camposInvalidosForm, setCamposInvalidosForm] = useState([]);
  const estilo =
    "Metropolis-Bold placeholder:text-slate-400 block bg-white w-full rounded-md mx-1 py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm";

  const navigate = useNavigate();
  const [documentos, setDocumentos] = useState([]);
  useEffect(() => {
    getIDTypeService()
      .then((resp) => {
        console.log(resp)
        if (resp.data.success) {
          setDocumentos(resp.data.data);
        }
      })
      .catch((error) => {
        swal({
          title: "Error",
          text: `Sucedió un error: ${error}`,
          icon: "error",
          dangerMode: true,
        });
      });
  }, []);
  const [formValues, handleInputChange] = useForm({
    tipo_documento: "",
    documento: "",
    nombres: "",
    apellidos: "",
    correo: "",
  });

  const { documento, tipo_documento, nombres, apellidos, correo } = formValues;

  const handleCreate = (e) => {
    e.preventDefault();
    let camposInvalidos = obtenerCamposInvalidosDelForm(formValues);
    if (camposInvalidos.length > 0) {
      setCamposInvalidosForm(camposInvalidos);
    } else {

      formValues.tipo_documento = parseFloat(formValues.tipo_documento);
      createStudentsService(formValues).then((resp) => {
        if (resp.data.success) {
          swal(
            `Estudiante ${formValues.nombres} ${formValues.apellidos} fue creado con Éxito`,
            {
              icon: "success",
            }
          );
          navigate("../");
        } else {
          if (resp.data.code == 401) {
            swal({
              title: "Alerta!!!",
              text: `${resp.data.data.message}`,
              icon: "warning",
              dangerMode: true,
            }).then(function () {
              window.location = "/bienvenido";
            });
          } else {
            swal({
              title: "Error",
              text: resp.data.data[0],
              icon: "error",
              dangerMode: true,
            });
          }

        }
      });
    }
  };

  const handleOnBlur = (e) => {
    /*  e.preventDefault();*/
    let camposInvalidos = obtenerCamposInvalidosDelForm(formValues);
    console.log("Campos Invalidos: " + JSON.stringify(camposInvalidos));
    if (camposInvalidos.length > 0) {
      setCamposInvalidosForm(camposInvalidos);
    }
  }

  
  const obtenerCamposInvalidosDelForm = (formValues) => {

    let camposInvalidos = [];
    if (formValues.tipo_documento === "") {
      camposInvalidos.push("tipo_documento");
    }
    if (formValues.documento === "") {
      camposInvalidos.push("documento");
    }
    if (formValues.nombres === "") {
      camposInvalidos.push("nombres");
    }
    if (formValues.apellidos === "") {
      camposInvalidos.push("apellidos");
    }
    if (formValues.correo === "") {
      camposInvalidos.push("correo");
    }

    return camposInvalidos;
  };

  return (
    <>
      {camposInvalidosForm.includes(
        "tipo_documento",
        "fechaCertificacion",
        "documento",
        "nombres",
        "apellidos",
        "correo"
      ) && (
          <span className="flex bg-[#FBE9EA] px-6 py-2 mt-5 ml-10 mr-10 border-l-4 border-l-rose-600 text-base text-[#4d4d4d] Metropolis">
            <b>Advertencia:</b>Los campos marcados en Rojo son Obligatorios
          </span>
        )}
      {
        hasCrearPermission && (
          <div className="mt-3">
            <h1 className="text-base lg:text-3xl my-5 text-center Metropolis-Extra-Bold">
              Crear Estudiante
            </h1>
            <div className="flex mt-3">
              <div className="lg:w-3/4  lg:m-auto px-5 py-5 rounded-2xl bg-[#67b5dd]">
                <div className="flex">
                  <div className="d-sm-none lg:w-2/6 rounded-lg  max-full mx-2 bg-[#243f59] Metropolis-Bold text-2xl text-white leading-loose">
                    <ul>
                      <li className="pl-3">
                        <label>Tipo de documento</label>
                      </li>
                      <li className="pl-3">
                        <label>Documento</label>
                      </li>
                      <li className="pl-3">
                        <label>Nombre(s)</label>
                      </li>
                      <li className="pl-3">
                        <label>Apellido(s)</label>
                      </li>
                      <li className="pl-3">
                        <label>Correo</label>
                      </li>
                    </ul>
                  </div>
                  <div className="lg:w-4/6 w-6/6">
                    <form onSubmit={handleCreate}>
                      <div className="mb-3">
                        <select
                          className={
                            camposInvalidosForm.includes("tipo_documento")
                              ? estilo + " border-2 border-rose-600"
                              : estilo
                          }
                          name="tipo_documento"
                          value={tipo_documento}
                          onChange={handleInputChange}
                        >
                          <option value="">Seleccione Tipo de Documento</option>
                          {documentos.map((tipo_documento, index) => {
                            return (
                              <option
                                key={index}
                                value={tipo_documento.tipo_documentoId}
                              >
                                {tipo_documento.tipo_documento}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="mb-3 ">
                        <input
                          type="text"
                          placeholder="Numero de Documento"
                          className={
                            camposInvalidosForm.includes("documento")
                              ? estilo + " border-2 border-rose-600"
                              : estilo
                          }
                          name="documento"
                          autoComplete="off"
                          value={documento}
                          onChange={handleInputChange}
                          onBlur={handleOnBlur}
                        />
                      </div>
                      <div className="mb-3 ">
                        <input
                          type="text"
                          placeholder="Nombre(s)"
                          className={
                            camposInvalidosForm.includes("nombres")
                              ? estilo + " border-2 border-rose-600"
                              : estilo
                          }
                          name="nombres"
                          autoComplete="off"
                          value={nombres}
                          onChange={handleInputChange}
                          onBlur={handleOnBlur}
                        />
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          placeholder="Apellido(s)"
                          className={
                            camposInvalidosForm.includes("apellidos")
                              ? estilo + " border-2 border-rose-600"
                              : estilo
                          }
                          name="apellidos"
                          autoComplete="off"
                          value={apellidos}
                          onChange={handleInputChange}
                          onBlur={handleOnBlur}
                        />
                      </div>
                      <div className="mb-3">
                        <input
                          type="email"
                          placeholder="E-mail"
                          className={
                            camposInvalidosForm.includes("correo")
                              ? estilo + " border-2 border-rose-600"
                              : estilo
                          }
                          name="correo"

                          autoComplete="off"
                          value={correo}
                          onChange={handleInputChange}
                          onBlur={handleOnBlur}
                        />
                      </div>
                      <input
                        className="bg-[#93b33a] rounded px-6 py-2 text-base text-white Metropolis-Bold"
                        type="submit"
                        value={"Guardar"}
                      />
                      <BackToPage texto="Cancelar" />
                    </form>
                    <br />
                    <div className='Metropolis-Regular text-1xl mx-1 py-2 pl-9 pr-3 bg-gray-300 w-full rounded-md'><b>Nota:</b> Se debe primero crear el estudiante antes de asignar cursos, para asignar cursos a este ir a editar el estudiante</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
};
