import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { getRolesService, deleteRoleService } from "../../selectors/getRoles";
import { permisos } from "../../helper/permissions";

export const ListRoles = () => {
  const seccion = 'Roles'
  permisos(seccion, 'listar', 'alerta')
  const hasListarPermission = permisos(seccion, 'listar')
  const hasEditarPermission = permisos(seccion, 'editar')
  const hasVerPermission = permisos(seccion, 'ver')
  const hasBorrarPermission = permisos(seccion, 'borrar')
  const hasCrearPermission = permisos(seccion, 'crear')

  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);

  let classBg = "bg-[#fff]";
  useEffect(() => {
    getRolesService()
      .then((resp) => {
        if (resp.data.success) {
          setRoles(resp.data.data);
        } else {
          if (resp.data.code == 401) {
            swal({
              title: "Alerta!!!",
              text: `${resp.data.data.message}`,
              icon: "warning",
              dangerMode: true,
            }).then(function () {
              window.location = "/bienvenido";
            });
          }
        }
      })
  }, []);

  const handleClick = (id) => {
    swal({
      title: "Alerta!!!",
      text: "¿Está seguro que desea eliminar el registro?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {

        let NewListRoles = roles.filter(
          (role) => role.id !== id
        );


        deleteRoleService(id)
          .then((resp) => {
            if (resp.data.success) {
              setRoles(NewListRoles);
              swal("Rol Eliminado con Éxito", {
                icon: "success",
              });
            } else {
              if (resp.data.code == 401) {
                swal({
                  title: "Alerta!!!",
                  text: `${resp.data.data.message}`,
                  icon: "warning",
                  dangerMode: true,
                }).then(function () {
                  window.location = "/bienvenido";
                });
              } else {
                swal({
                  title: "Error",
                  text: resp.data.data[0],
                  icon: "error",
                  dangerMode: true
                });
              }


            }
          })
          .catch((error) => {
            swal({
              title: "Error",
              text: `Sucedió un error: ${error}`,
              icon: "error",
              dangerMode: true,
            });
          });
      } else {
        swal("No se alteró la base de datos");
      }
    });
  };

  return (
    <>
      {
        hasListarPermission && (
          <div className="flex flex-col px-5 mt-5">
            <div className="overflow-x-auto">
              <div className="p-5 inline-block min-w-full bg-[#67b5dd] rounded-[12px] mb-5 ">

                <div className="p-3 content-center">

                  <div className="overflow-hidden content-center">
                    <table className="min-w-full border-x border-[#243F59] text-center rounded-[12px] overflow-hidden">
                      <thead className="border-x bg-[#243f59] rounded-[12px]">
                        <tr>
                          <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                            Rol
                          </th>
                          <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                            Descripción
                          </th>
                          <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                            Acciones
                          </th>
                        </tr>
                      </thead>
                      <tbody className="border-b bg-[#fff]">
                        {roles.map((role, index) => {
                          if (index % 2 === 0) {
                            classBg = "bg-[#ececec]";
                          } else {
                            classBg = "bg-[#fff]";
                          }
                          return (
                            <tr key={role.id} className={`border-b ${classBg}`}>
                              <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r">
                                {role.name}
                              </td>
                              <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r">
                                {role.description}
                              </td>
                              <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r bg-[#243f5926] w-44">
                                <div className="flex justify-center">
                                  {hasEditarPermission && (
                                    <Link
                                      className="text-blue-600 visited:text-black-600"
                                      to={`./edit/${role.id}`}
                                    >
                                      {" "}
                                      <img
                                        className="w-6"
                                        src={`/assets/icons/edit.png`}
                                        alt="Editar"
                                        title="Editar"
                                      />
                                    </Link>
                                  )}
                                  {hasBorrarPermission && (
                                    <img
                                      onClick={(event) => handleClick(role.id)}
                                      className="w-6"
                                      src={`/assets/icons/delete.png`}
                                      alt="Eliminar"
                                      title="Eliminar"
                                    />
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

    </>
  );
};
