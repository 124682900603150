import React, { useState } from "react";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import { createTemplatesService } from "../../selectors/pantillasServices";
import { BackToPage } from "../generals/backToPage/BackToPage";
import { permisos } from "../../helper/permissions";

export const CreateTemplates = () => {

  const seccion = 'Plantillas'
  permisos(seccion, 'listar', 'alerta')
  const hasListarPermission = permisos(seccion, 'listar')
  const hasEditarPermission = permisos(seccion, 'editar')
  const hasVerPermission = permisos(seccion, 'ver')
  const hasBorrarPermission = permisos(seccion, 'borrar')
  const hasCrearPermission = permisos(seccion, 'crear')


  const navigate = useNavigate();
  const [formValues, handleInputChange] = useForm({
    nombrePlantilla: "",
    imgCertificado: null,
  });
  const [base64Image, setbase64Image] = useState({})
  const { nombrePlantilla, imgCertificado } = formValues;
  const handleCreate = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("imgCertificado", base64Image);
    formData.append("nombrePlantilla", formValues.nombrePlantilla);
    createTemplatesService(formData)
      .then((respuesta) => {
        if (respuesta.data.success) {
          swal(`Plantilla ${formValues.nombrePlantilla} fue creada con Exito`, {
            icon: "success",
          });
          navigate("../");
        } else {
          if (respuesta.data.code == 401) {
            swal({
              title: "Alerta!!!",
              text: `${respuesta.data.data.message}`,
              icon: "warning",
              dangerMode: true,
            }).then(function () {
              window.location = "/bienvenido";
            });
          } else {
            swal({
              title: "Error",
              text: `Error: ${JSON.stringify(respuesta.data.data.message)}`,
              icon: "error",
              dangerMode: true
            });
          }

        }
      })
      .catch((error) => {
        swal({
          title: "Error",
          text: `Sucedió un error: ${error}`,
          icon: "error",
          dangerMode: true,
        });
      });
  };

 

  const getBase64Image = (event) => {
    setbase64Image(event.target.files[0]);
    console.log(base64Image.size)
  }

  return (
    <>
      {
        hasCrearPermission && (
          <div className="mt-3">
            <h1 className="text-center text-base lg:text-3xl my-5 Metropolis-Extra-Bold">
              Crear plantilla
            </h1>
            <div className="flex mt-3">
              <div className="w-3/4 m-auto px-5 py-5 rounded-2xl bg-[#67b5dd]">
                <div className="flex">
                  <div className="w-2/6 rounded-lg  max-full mx-2 bg-[#243f59] Metropolis-Bold text-2xl text-white leading-loose">
                    <ul>
                      <li className="pl-3">
                        <label>Nombre plantilla</label>
                      </li>
                      <li className="pl-3">
                        <label>Imagen certificados</label>
                      </li>
                    </ul>
                  </div>
                  <div className="w-4/6">
                    <form onSubmit={handleCreate}>
                      <div className="mb-3 ">
                        <input
                          type="text"
                          placeholder="Nombre plantilla*"
                          className="Metropolis-Bold placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md mx-1 py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                          name="nombrePlantilla"
                          autoComplete="off"
                          value={nombrePlantilla}
                          onChange={handleInputChange}
                       
                    />
                      </div>
                      <div className="mb-3">
                        <input
                          type="file"
                          placeholder="Imagen Certificado"
                          className="Metropolis-Bold placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md mx-1 py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                          autoComplete="off"
                          onChange={getBase64Image}
                        />
                        <span className="flex bg-[#243f59] bg-opacity-80 px-9 py-1 mt-0 ml-2 mr-0 rounded-b-md text-white text-xs Metropolis"><b>Tamaño de Imagen:</b>&nbsp; 2370x1943 px</span>
                        {base64Image.size !== undefined ?
                          <span className="flex bg-[#243f59] bg-opacity-80 px-9 py-1 mt-0 ml-2 mr-0 rounded-b-md text-white text-xs Metropolis"><b>Tamaño de Imagen:</b>&nbsp; {base64Image.size + 'Kb'}</span>
                          : ''}
                      </div>

                      <input
                        className="bg-[#93b33a] rounded px-6 py-2 text-base text-white Metropolis-Bold"
                        type="submit"
                        value={"Guardar"}
                      />
                      <BackToPage texto="Cancelar" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}


    </>
  );
};
