import axios from 'axios';
/**
 * http://localhost/certificate-backend/public/api'
 * @returns en la url mandar ?api_token=token
 */
let token = ''
if (localStorage.getItem('user')) {
    token = JSON.parse(localStorage.getItem('user'))['api_token'];

    let user = JSON.parse(localStorage.getItem('user'));
    user.permisos = {'permisos':{'cursos':{'crear':'crear','actualizar':'actualizar'}}};
    localStorage.setItem('user', JSON.stringify(user));

    
    console.log(localStorage.getItem('user'));
}

export const getCoursesCanvas = (id) => {
    if (id === '') {
        throw new Error('Invalid id');
    }
    return axios.get(`${process.env.REACT_APP_API_URL}/canvas/get/curso/${id}`);
}

export const syncCertificatesService = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/canvas/courses?api_token=${token}`);
}
