import React, { useEffect, useState } from "react";
import { permisos } from "../../helper/permissions";

export const ViewRoles = () => {
  
  const seccion = 'Roles'
  permisos(seccion, 'listar', 'alerta')
  const hasListarPermission = permisos(seccion, 'listar')
  const hasEditarPermission = permisos(seccion, 'editar')
  const hasVerPermission = permisos(seccion, 'ver')
  const hasBorrarPermission = permisos(seccion, 'borrar')
  const hasCrearPermission = permisos(seccion, 'crear')


  return (
    <>

    </>
  );
};
