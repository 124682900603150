import axios from 'axios';

let token = ''
if (localStorage.getItem('user')) {
    
    token = JSON.parse(localStorage.getItem('user'))['api_token'];
}


export const BulkLoadStudentsAndCertificatesServices = (file) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/certificados/cargar?api_token=${token}`, file);
};

export const BulkLoadCursosServices = (file) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/curso/cargar?api_token=${token}`, file);

};