import React, { useEffect, useState } from 'react'
import swal from "sweetalert";
import { useParams } from 'react-router-dom';
import { getUserByIdService } from '../../selectors/getUsers'
import { DetailComponent } from '../generals/detailComponent/DetailComponent';
import { permisos } from "../../helper/permissions";

export const ViewUsers = () => {

  const seccion = 'Usuarios'
  permisos(seccion, 'listar', 'alerta')
  const hasListarPermission = permisos(seccion, 'listar')
  const hasEditarPermission = permisos(seccion, 'editar')
  const hasVerPermission = permisos(seccion, 'ver')
  const hasBorrarPermission = permisos(seccion, 'borrar')
  const hasCrearPermission = permisos(seccion, 'crear')

  
  const [defaultValues, setDefaultValues] = useState({})
  const params = useParams();
  useEffect(() => {
    getUserByIdService(params.id).then((user) => {
      if (user.data.success) {
        const result = { ...defaultValues, ...user.data.data }
        setDefaultValues(result)
      } else {
        if (user.data.code == 401) {
          swal({
            title: "Alerta!!!",
            text: `${user.data.data.message}`,
            icon: "warning",
            dangerMode: true,
          }).then(function () {
            window.location = "/bienvenido";
          });
        }
      }
    }).catch((error) => {
      swal({
        title: "Error",
        text: `Sucedió un error: ${error}`,
        icon: "error",
        dangerMode: true,
      });
    });
  }, [])
  const data = {
    type: "Usuarios",
    content: [
      {
        name: "Nombres",
        value: defaultValues.name,
      },
      {
        name: "Apellidos",
        value: defaultValues.lastname,
      },
      {
        name: "Correo",
        value: defaultValues.email,
      }
    ],
  };
  return (
    <>
      <DetailComponent info={data} />
    </>
  )
}
