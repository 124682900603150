import React, { useState } from "react";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import { editImageStorageService } from "../../selectors/imageStorageService";
import { BackToPage } from "../generals/backToPage/BackToPage";
import { permisos } from "../../helper/permissions";

export const EditImageStorage = () => {
  const seccion = 'Galeria'
  permisos(seccion, 'listar', 'alerta')
  const hasListarPermission = permisos(seccion, 'listar')
  const hasEditarPermission = permisos(seccion, 'editar')
  const hasVerPermission = permisos(seccion, 'ver')
  const hasBorrarPermission = permisos(seccion, 'borrar')
  const hasCrearPermission = permisos(seccion, 'crear')

  const tipos = [{ name: 'Firmas', type: 'Firmas' }, { name: 'Logos', type: 'Logos' }];
  const params = useParams();
  const navigate = useNavigate();
  const [camposInvalidosForm, setCamposInvalidosForm] = useState([]);
  const [imagenMuestra, setImagenMuestra] = useState('');
  const [tiposImagen, setTiposImagen] = useState(tipos);

  const estilo = "Metropolis-Bold placeholder:text-slate-400 block bg-white w-full rounded-md mx-1 py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm";

  let defaultValues = {};
  const cargarData = () => {
    let repositorio = {};
    const repositorioById = JSON.parse(localStorage.getItem("listImages"));
    repositorio = repositorioById.find((repositorio) => repositorio.id === parseFloat(params.id));
    return repositorio
  }

  defaultValues = cargarData();
  const [formValues, handleInputChange] = useForm({
    id: defaultValues.id,
    title: defaultValues.title,
    picture: defaultValues.picture,
    type: defaultValues.type,
    description: defaultValues.description,
  });

  const { id, title, picture, type, description } = formValues;

  const handleEdit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("picture", picture);
    formData.append("description", description);
    formData.append("type", type);

    let camposInvalidos = obtenerCamposInvalidosDelForm(formValues);
    if (camposInvalidos.length > 0) {
      setCamposInvalidosForm(camposInvalidos);
    } else {

      editImageStorageService(formValues.id, formData).then((resp) => {
        console.log(resp);
        if (resp.data.success) {
          swal("Imagen editado con éxito", {
            icon: "success",
          });

          navigate("../")
        } else {
          if (resp.data.code == 401) {
            swal({
              title: "Alerta!!!",
              text: `${resp.data.data.message}`,
              icon: "warning",
              dangerMode: true,
            }).then(function () {
              window.location = "/bienvenido";
            });
          } else {
            swal({
              title: "Error",
              text: `Error: ${resp.data.data[0]}`,
              icon: "error",
              dangerMode: true
            });
          }


        }
      }).catch((error) => {
        swal({
          title: "Error",
          text: `Sucedió un error: ${error}`,
          icon: "error",
          dangerMode: true,
        });
      });
    }
  };

  const handleOnBlur = (e) => {
    /*  e.preventDefault();*/
    let camposInvalidos = obtenerCamposInvalidosDelForm(formValues);
    console.log("Campos Invalidos: " + JSON.stringify(camposInvalidos));
    if (camposInvalidos.length > 0) {
      setCamposInvalidosForm(camposInvalidos);
    }
  }


  const obtenerCamposInvalidosDelForm = (formValues) => {
    let camposInvalidos = [];
    /**
     * Campos a validar
     */
    if (formValues.title === '') {
      camposInvalidos.push("title");
    }
    if (formValues.type === '' || formValues.type === undefined || formValues.type === null) {
      camposInvalidos.push("type");
    }
    if (formValues.description === '') {
      camposInvalidos.push("description");
    }
    return camposInvalidos;
  };
  const getBase64Image = (event) => {
    formValues.picture = event.target.files[0]
    const file = event.target.files[0];
    let base64String = '';
    const reader = new FileReader();
    reader.onload = function () {
      base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
      setImagenMuestra(`data:image/png;base64,${base64String}`)
    }
    reader.readAsDataURL(file);
  };
  return (
    <>
      {camposInvalidosForm.includes('type', 'title', 'description') && (
        <span className="flex bg-[#FBE9EA] px-6 py-2 mt-5 ml-10 mr-10 border-l-4 border-l-rose-600 text-base text-[#4d4d4d] Metropolis">
          <b>Advertencia:</b>&nbsp;Los campos marcados en Rojo son Obligatorios
        </span>
      )}
      {
        hasEditarPermission && (
          <div className="mt-3">
            <h1 className="text-center text-base lg:text-3xl my-5 Metropolis-Extra-Bold">
              Editar Imagen
            </h1>
            <div className="flex mt-3">
              <div className="w-3/4 m-auto px-5 py-5 rounded-2xl bg-[#67b5dd]">
                <div className="flex">
                  <div className="w-2/6 rounded-lg  max-full mx-2 bg-[#243f59] Metropolis-Bold text-2xl text-white leading-loose">
                    <ul>
                      <li className="ml-3">
                        <label>Nombre</label>
                      </li>
                      <li className="ml-3">
                        <label>Imagen</label>
                      </li>
                      <li className="ml-3">
                        <label>Tipo</label>
                      </li>
                      <li className="ml-3">
                        <label>Descripción</label>
                      </li>
                    </ul>
                  </div>
                  <div className="w-4/6">
                    {defaultValues !== {} ? (
                      <form onSubmit={handleEdit}>
                        <div className="mb-3">
                          <input type="hidden" value={id} />
                          <input
                            type="text "
                            placeholder="Nombre"
                            className={
                              camposInvalidosForm.includes("title")
                                ? estilo + " border-2 border-rose-600"
                                : estilo
                            }
                            name="title"
                            autoComplete="off"
                            value={title}
                            onChange={handleInputChange}
                            onBlur={handleOnBlur}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <input
                            type="file"
                            placeholder="Imagen Certificado"
                            className="Metropolis-Bold placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md mx-1 py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                            autoComplete="off"
                            onChange={getBase64Image}
                            onBlur={handleOnBlur}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <select
                            className={
                              camposInvalidosForm.includes("type")
                                ? estilo + " border-2 border-rose-600"
                                : estilo
                            }
                            aria-label="Default select example"
                            name="type"
                            autoComplete="off"
                            value={type}
                            required
                            onChange={handleInputChange}
                            onBlur={handleOnBlur}
                          >
                            <option value="">Seleccione un Tipo</option>
                            {tiposImagen.map((tipoImagen, index) => {
                              return (
                                <option
                                  key={index}
                                  value={tipoImagen.type}
                                  selected={type === tipoImagen.type}
                                >{tipoImagen.name}</option>
                              )
                            })}
                          </select>
                        </div>
                        <div className="mb-3">
                          <input
                            type="text "
                            placeholder="Descripción"
                            className={
                              camposInvalidosForm.includes("description")
                                ? estilo + " border-2 border-rose-600"
                                : estilo
                            }
                            name="description"
                            autoComplete="off"
                            value={description}
                            onChange={handleInputChange}
                            onBlur={handleOnBlur}
                            required
                          />
                        </div>
                        <input
                          className="bg-[#93b33a] rounded px-6 py-2 text-base text-white Metropolis-Bold"
                          type="submit"
                          value={"Guardar"}
                        />
                        <BackToPage texto="Cancelar" />
                      </form>
                    ) : (
                      <p>Cargando...</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {imagenMuestra !== '' ?
              <div className="flex mt-3">
                <div className="w-3/4 m-auto px-5 py-5 rounded-2xl bg-[#67b5dd]">
                  <h1 className="text-left text-base lg:text-3xl my-5 Metropolis-Extra-Bold mb-4 text-white">Muestra</h1>
                  <img src={imagenMuestra} alt="" className="m-auto w-full" />
                </div>
              </div>
              : <div className="flex mt-3">
                <div className="w-3/4 m-auto px-5 py-5 rounded-2xl bg-[#67b5dd]">
                  <h1 className="text-left text-base lg:text-3xl my-5 Metropolis-Extra-Bold mb-4 text-white">Muestra</h1>
                  <img src={`${process.env.REACT_APP_IMAGES_URL}${picture}`} alt="" className="m-auto w-full" />
                </div>
              </div>}
          </div>
        )}

    </>
  );
};