import axios from 'axios';
console.log(process.env.REACT_APP_API_URL)
export const LoginService = async(data) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/login`, data).then((response) => {
        if (response.data.success) {
            localStorage.setItem('user', JSON.stringify(response.data.data));
            window.location.href = '/bienvenido';
        } else {
            alert("Credenciales Incorrectas")
            return false;
        }
    }).catch(err => { console.error(err); return false; });
}