import React, { useState, useRef, useEffect } from "react";
import swal from "sweetalert";
import { useForm } from "../../hooks/useForm";
import { useNavigate, useParams } from "react-router-dom";
import { editTemplatesService } from "../../selectors/pantillasServices";
import { getPreviewPDFService } from "../../selectors/pantillasServices";
import { BackToPage } from "../generals/backToPage/BackToPage";
import "./template.css";
import * as monaco from "monaco-editor";
import MonacoEditor from "@monaco-editor/react";
import { constrainedEditor } from "constrained-editor-plugin";
import { permisos } from "../../helper/permissions";

export const EditTemplates = () => {
  const seccion = "Plantillas";
  permisos(seccion, "listar", "alerta");
  const hasListarPermission = permisos(seccion, "listar");
  const hasEditarPermission = permisos(seccion, "editar");
  const hasVerPermission = permisos(seccion, "ver");
  const hasBorrarPermission = permisos(seccion, "borrar");
  const hasCrearPermission = permisos(seccion, "crear");
  // Declara un estado para controlar si se está cargando o no la respuesta del servicio
  const [isLoading, setIsLoading] = useState(false);
  // Crear una referencia al div que deseas hacer scroll
  const divRef = useRef(null);

  const editorRef = useRef(null);
  let restrictions = [];

  const params = useParams();
  const navigate = useNavigate();
  const [imgClassModal, setImgClassModal] = useState("");
  const [selectedFile, setSelectedFile] = React.useState(null);
  let defaultValues = {};
  let plantilla = {};
  const cargaData = () => {
    const plantillaById = JSON.parse(localStorage.getItem("plantillas"));
    plantilla = plantillaById.find(
      (plantilla) => plantilla.plantillaId === parseFloat(params.id)
    );
    return plantilla;
  };
  defaultValues = cargaData();

  const [cssValue, setCss] = useState(defaultValues.css);
  const [pdfURL, setPdfURL] = useState("");

  function handleEditorChange(newValue, event) {
    setCss(newValue);
  }

  const handleEditCss = (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("plantillaId", formValues.plantillaId);
      formData.append("css", cssValue);

      // Establece isLoading a true para mostrar el spinner
      setIsLoading(true);

      getPreviewPDFService(formValues.plantillaId, formData)
        .then((response) => {
          // Crear una URL del blob para el PDF
          const pdfURL = URL.createObjectURL(response.data);
          console.log(pdfURL);
          // Establecer la URL del PDF en el estado para mostrarlo en el componente
          setPdfURL(pdfURL);
        })
        .catch((error) => {
          console.error("Error al obtener el PDF:", error);
        })
        .finally(() => {
          // Establece isLoading a false cuando la respuesta del servicio haya llegado, independientemente de si fue exitosa o no
          setIsLoading(false);
          /* divRef.current.scrollIntoView({ behavior: 'smooth' }); */
        });
    } catch (error) {
      console.error("Error al obtener el PDF:", error);
    }
  };

  useEffect(() => {
    if (!isLoading && pdfURL && divRef.current) {
      console.log("scroll");
      divRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isLoading, pdfURL]);

  const [formValues, handleInputChange] = useForm({
    plantillaId: defaultValues.plantillaId,
    nombrePlantilla: defaultValues.nombrePlantilla,
    imgCertificado: defaultValues.imgCertificado,
    css: defaultValues.css,
  });

  const { plantillaId, nombrePlantilla, imgCertificado, css } = formValues;
  const handleEdit = (e) => {
    e.preventDefault();
    console.log(formValues);
    console.log("cssValue" + cssValue);
    const formData = new FormData();
    formData.append("imgCertificado", selectedFile);
    formData.append("nombrePlantilla", formValues.nombrePlantilla);
    formData.append("css", cssValue);

    try {
      editTemplatesService(formValues.plantillaId, formData).then((resp) => {
        if (resp.data.success) {
          if (e.target.name == "send") {
            swal("Plantilla editada con Éxito", {
              icon: "success",
            });
            navigate("../");
          } else {
            swal("Plantilla disponible", {
              icon: "success",
            });
          }
        } else {
          if (resp.data.code == 401) {
            swal({
              title: "Alerta!!!",
              text: `${resp.data.data.message}`,
              icon: "warning",
              dangerMode: true,
            }).then(function () {
              window.location = "/bienvenido";
            });
          } else {
            swal({
              title: "Error",
              text: resp.data.data[0],
              icon: "error",
              dangerMode: true,
            });
          }
        }
      });
    } catch (error) {
      swal({
        title: "Error",
        text: `Sucedió un error: ${error}`,
        icon: "error",
        dangerMode: true,
      });
    }
  };
  const handleClickCloseOpendModal = (swicther) => {
    if (swicther) {
      setImgClassModal("show");
    } else {
      setImgClassModal("");
    }
  };
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  let classToSearch = [
    [".logos {"],
    [".cont-body {"],
    [".firmas {"],
    [".nombre {"],
    [".documento {"],
    [".curso {"],
    [".duracion {"],
    [".certificacion {"],
    [".text1 {"],
  ];
  let max = classToSearch.length;
  const lines = css.split("\n");
  let maxlines = lines.length;

  classToSearch.forEach(function (elemento, indice, array) {
    const searchString = elemento[0];
    const index = lines.findIndex((line) => line.indexOf(searchString) !== -1); //este dato me retorna la línea donde encuentra el string a buscar
    const pos = index + 1;

    classToSearch[indice].push(pos + 1);

    if (pos == 1) {
    } else if (indice + 1 == max) {
      classToSearch[indice].push(maxlines);
      classToSearch[indice - 1].push(pos - 2);
    } else {
      classToSearch[indice - 1].push(pos - 2);
    }
  });

  let vector;
  classToSearch.forEach(function (elemento, indice, array) {
    vector = [classToSearch[indice][1], 1, classToSearch[indice][2], 1];
    restrictions.push({
      range: vector,
      allowMultiline: false,
    });
  });

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
    const constrainedInstance = constrainedEditor(monaco);
    const model = editor.getModel();
    constrainedInstance.initializeIn(editor);

    constrainedInstance.addRestrictionsTo(model, restrictions);
  }

  return (
    <>
      {hasEditarPermission && (
        <div className="mt-3">
          <h1 className="text-center text-base lg:text-3xl my-5 Metropolis-Extra-Bold">
            Editar plantilla
          </h1>
          <div className="flex mt-3">
            <div className="w-3/4 m-auto px-5 py-5 rounded-2xl bg-[#67b5dd]">
              <div className="flex">
                <div className="w-2/6 rounded-lg  max-full mx-2 bg-[#243f59] Metropolis-Bold text-2xl text-white leading-loose">
                  <ul>
                    <li className="pl-3">
                      <label>Nombre Plantilla</label>
                    </li>
                    <li className="pl-3">
                      <label>imagen certificado</label>
                    </li>
                    <li className="pl-3">
                      <label>CSS</label>
                    </li>
                  </ul>

                  <div className="fuentes">
                    <h1>Fuentes disponibles</h1>
                    <ol>                                    
                      <li>Helvetica</li>
                      <li>Helvetica Bold</li>
                      <li>Tahoma</li>
                      <li>Pacifico</li>
                      <li>Croissant One</li>
                      </ol>
                  </div>
                </div>
                <div className="w-4/6">
                  {defaultValues !== {} ? (
                    <>
                      <form>
                        <div className="mb-3 ">
                          <input type="hidden" value={plantillaId} />
                          <input
                            type="text"
                            placeholder="Nombre plantilla"
                            className="Metropolis-Bold placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md mx-1 py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                            name="nombrePlantilla"
                            autoComplete="off"
                            value={nombrePlantilla}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="mb-3">
                          <input type="file" onChange={handleFileSelect} />
                          <span className="flex bg-[#243f59] bg-opacity-80 px-9 py-1 mt-0 ml-2 mr-0 rounded-b-md text-white text-xs Metropolis">
                            <b>Tamaño de Imagen:</b>&nbsp; 2474x1943 px
                          </span>
                        </div>
                        <div className="mb-3">
                          <label>CSS </label>
                          <MonacoEditor
                            height="500px"
                            language="css"
                            name="css"
                            value={css}
                            onMount={handleEditorDidMount}
                            theme="vs-dark"
                            onChange={handleEditorChange}
                          />
                        </div>
                        <input
                          className="bg-[#93b33a] rounded px-6 py-2 mx-2 text-base text-white Metropolis-Bold"
                          type="submit"
                          name="send"
                          value={"Guardar"}
                          onClick={handleEdit}
                        />
                        <input
                          className="bg-[#243F59] rounded px-6 py-2 mx-2 text-base text-white Metropolis-Bold"
                          type="submit"
                          name="apply"
                          value={"Previsualizar PDF"}
                          onClick={handleEditCss}
                        />
                        {isLoading && ( // Mostrar el spinner de carga sobre todos los contenidos
                          <div className="spinner-overlay">
                            <div className="spinner"></div>
                          </div>
                        )}
                        <BackToPage texto="Cancelar" />
                      </form>
                    </>
                  ) : (
                    <p>Cargando...</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          {pdfURL && (
            <div
              ref={divRef}
              className="preview w-3/4 m-auto px-5 py-5 rounded-2xl bg-[#67b5dd] mt-5"
            >
              <div className="mb-3">
                <h1 className="text-center text-white lg:text-3xl my-5 Metropolis-Extra-Bold">
                  Screen Plantilla
                </h1>

                <object
                  data={pdfURL}
                  type="application/pdf"
                  width="100%"
                  height="600px"
                >
                  <p>
                    Tu navegador no admite la visualización de archivos PDF.
                    Puedes <a href={pdfURL}>descargarlo aquí</a> en su lugar.
                  </p>
                </object>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
