import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { getAllStudentsService } from "../../selectors/getStudents";
import { deleteStudentsService } from "../../selectors/getStudents";
import { useForm } from "../../hooks/useForm";
import { permisos } from "../../helper/permissions";

export const ListStudents = () => {
  const seccion = 'Estudiantes'
  permisos(seccion, 'listar', 'alerta')
  const hasListarPermission = permisos(seccion, 'listar')
  const hasEditarPermission = permisos(seccion, 'editar')
  const hasVerPermission = permisos(seccion, 'ver')
  const hasBorrarPermission = permisos(seccion, 'borrar')
  const hasCrearPermission = permisos(seccion, 'crear')


  const [Students, setStudents] = useState([]);
  const [page, setPages] = useState({});
  const [formValues, handleInputChange] = useForm({
    searchText: "",
  });
  const { searchText } = formValues;

  const handleSearch = (event) => {
    event.preventDefault();
    getAllStudentsService(1, searchText)
      .then((resp) => {
        if (resp.data.success) {
          localStorage.setItem("Students", JSON.stringify(resp.data.data.data));
          setStudents(resp.data.data.data);
          setPages(resp.data.data.paginacion);
        } else {
          if (resp.data.code == 401) {
            swal({
              title: "Alerta!!!",
              text: `${resp.data.data.message}`,
              icon: "warning",
              dangerMode: true,
            }).then(function () {
              window.location = "/bienvenido";
            });
          }
        }
      })
      .catch((error) => {
        swal({
          title: "Error",
          text: `Sucedió un error: ${error}`,
          icon: "error",
          dangerMode: true,
        });
      });
  };
  let classBg = "bg-[#fff]";
  useEffect(() => {
    getAllStudentsService(1, "")
      .then((resp) => {
        if (resp.data.success) {
          localStorage.setItem("Students", JSON.stringify(resp.data.data.data));
          setStudents(resp.data.data.data);
          setPages(resp.data.data.paginacion);
        } else {
          if (resp.data.code == 401) {
            swal({
              title: "Alerta!!!",
              text: `${resp.data.data.message}`,
              icon: "warning",
              dangerMode: true,
            }).then(function () {
              window.location = "/bienvenido";
            });
          }
        }
      })
      .catch((error) => {
        swal({
          title: "Error",
          text: `Sucedió un error: ${error}`,
          icon: "error",
          dangerMode: true,
        });
      });
  }, []);

  const handleClick = (id) => {
    swal({
      title: "Alerta!!!",
      text: "¿Está seguro que desea eliminar el registro?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let nuevaListaDeEstudiantes = Students.filter(
          (estudiante) => estudiante.userid !== id
        );

        deleteStudentsService(id)
          .then((resp) => {
            if (resp.data.success) {
              setStudents(nuevaListaDeEstudiantes);
              swal("Estudiante Eliminado con Éxito", {
                icon: "success",
              });
            } else {
              if (resp.data.code == 401) {
                swal({
                  title: "Alerta!!!",
                  text: `${resp.data.data.message}`,
                  icon: "warning",
                  dangerMode: true,
                }).then(function () {
                  window.location = "/bienvenido";
                });
              } else {
                swal({
                  title: "Error",
                  text: resp.data.data[0],
                  icon: "error",
                  dangerMode: true
                });
              }

            }
          })
          .catch((error) => {
            swal({
              title: "Error",
              text: `Sucedió un error: ${error}`,
              icon: "error",
              dangerMode: true,
            });
          });
      } else {
        swal("No se alteró la base de datos");
      }
    });
  };

  /**
   * Paginación
   */
  const handleBackPage = (pageBack) => {
    getAllStudentsService(pageBack - 1, searchText)
      .then((resp) => {
        if (resp.data.success) {
          setStudents(resp.data.data.data);
          setPages(resp.data.data.paginacion);
          localStorage.setItem("Students", JSON.stringify(resp.data.data.data));
        } else {
          if (resp.data.code == 401) {
            swal({
              title: "Alerta!!!",
              text: `${resp.data.data.message}`,
              icon: "warning",
              dangerMode: true,
            }).then(function () {
              window.location = "/bienvenido";
            });
          }
        }
      })
      .catch((error) => {
        swal({
          title: "Error",
          text: `Sucedió un error: ${error}`,
          icon: "error",
          dangerMode: true,
        });
      });
  };
  const handleNextPage = (page) => {
    getAllStudentsService(page + 1, searchText)
      .then((resp) => {
        if (resp.data.success) {
          setStudents(resp.data.data.data);
          setPages(resp.data.data.paginacion);
          localStorage.setItem("Students", JSON.stringify(resp.data.data.data));
        } else {
          if (resp.data.code == 401) {
            swal({
              title: "Alerta!!!",
              text: `${resp.data.data.message}`,
              icon: "warning",
              dangerMode: true,
            }).then(function () {
              window.location = "/bienvenido";
            });
          }
        }
      })
      .catch((error) => {
        swal({
          title: "Error",
          text: `Sucedió un error: ${error}`,
          icon: "error",
          dangerMode: true,
        });
      });
  };
  const handleFirstPage = (page) => {
    getAllStudentsService(1, searchText)
      .then((resp) => {
        if (resp.data.success) {
          setStudents(resp.data.data.data);
          setPages(resp.data.data.paginacion);
          localStorage.setItem("Students", JSON.stringify(resp.data.data.data));
        } else {
          if (resp.data.code == 401) {
            swal({
              title: "Alerta!!!",
              text: `${resp.data.data.message}`,
              icon: "warning",
              dangerMode: true,
            }).then(function () {
              window.location = "/bienvenido";
            });
          }
        }
      })
      .catch((error) => {
        swal({
          title: "Error",
          text: `Sucedió un error: ${error}`,
          icon: "error",
          dangerMode: true,
        });
      });
  };
  const handleLastPage = (page) => {
    getAllStudentsService(page, searchText)
      .then((resp) => {
        if (resp.data.success) {
          setStudents(resp.data.data.data);
          setPages(resp.data.data.paginacion);
          localStorage.setItem("Students", JSON.stringify(resp.data.data.data));
        } else {
          if (resp.data.code == 401) {
            swal({
              title: "Alerta!!!",
              text: `${resp.data.data.message}`,
              icon: "warning",
              dangerMode: true,
            }).then(function () {
              window.location = "/bienvenido";
            });
          }
        }
      })
      .catch((error) => {
        swal({
          title: "Error",
          text: `Sucedió un error: ${error}`,
          icon: "error",
          dangerMode: true,
        });
      });
  };

  return (
    <>
      {
        hasListarPermission && (
          <div className="flex flex-col px-5 mt-5">
            <div className="overflow-x-auto">
              <div className="p-5 inline-block min-w-full bg-[#67b5dd] rounded-[12px] mb-5 ">
                <div className="p-3">
                  <div className="buscador sincronizar">
                    <form onSubmit={handleSearch}>
                      <div className="flex">
                        <div className="w-full">
                          <input
                            type="text"
                            className="rounded-[12px] form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none mr-3"
                            id="exampleFormControlInput1"
                            placeholder="Buscar"
                            name="searchText"
                            autoComplete='off'
                            value={searchText}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="pl-5">
                          <button
                            type="submit"
                            className=" py-2.5 bg-[#93b33a] text-white Metropolis-Bold text-xs leading-tight uppercase rounded-md shadow-md hover:bg-[#93b33a] hover:shadow-lg focus:bg-[#93b33a] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#93b33a] active:shadow-lg transition  pl-3 pr-3 duration-150 ease-in-out"
                          >
                            Buscar
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className='grid justify-items-center bg-[#243F59] bg-opacity-30 rounded-t-xl ml-3 mr-3'>
                  <span>
                    {page.onFirstPage !== true ?
                      <button onClick={(event) => handleFirstPage(page.currentPage)} className="bg-[#243F59] rounded px-3 py-2 text-base text-white Metropolis-Bold mb-3 mt-3 ml-1 mr-1">◀◀</button>
                      :
                      <button disabled onClick={(event) => handleFirstPage(page.currentPage)} className="bg-[#243F59] rounded px-3 py-2 text-base text-white Metropolis-Bold mb-3 mt-3 ml-1 mr-1 cursor-not-allowed opacity-50">◀◀</button>
                    }

                    {page.onFirstPage !== true ?
                      <button onClick={(event) => handleBackPage(page.currentPage)} className="bg-[#243F59] rounded px-4 py-2 text-base text-white Metropolis-Bold mb-3 mt-3 ml-1 mr-1">◀</button>
                      :
                      <button disabled onClick={(event) => handleBackPage(page.currentPage)} className="bg-[#243F59] rounded px-4 py-2 text-base text-white Metropolis-Bold mb-3 mt-3 ml-1 mr-1 cursor-not-allowed opacity-50">◀</button>
                    }

                    <span className="bg-white rounded px-4 py-2 text-base text-[#243F59] Metropolis mt-5 ml-1 mr-1">Página <b>{page.currentPage}</b> de <b>{page.lastPage}</b></span>

                    {page.hasMorePages === true ?
                      <button onClick={(event) => handleNextPage(page.currentPage)} className="bg-[#243F59] rounded px-4 py-2 text-base text-white Metropolis-Bold mb-3 mt-3 ml-1 mr-1">▶</button>
                      :
                      <button disabled onClick={(event) => handleNextPage(page.currentPage)} className="bg-[#243F59] rounded px-4 py-2 text-base text-white Metropolis-Bold mb-3 mt-3 ml-1 mr-1 cursor-not-allowed opacity-50">▶</button>
                    }

                    {page.hasMorePages !== false ?
                      <button onClick={(event) => handleLastPage(page.lastPage)} className="bg-[#243F59] rounded px-3 py-2 text-base text-white Metropolis-Bold mb-3 mt-3 ml-1 mr-1">▶▶</button>
                      :
                      <button disabled onClick={(event) => handleLastPage(page.lastPage)} className="bg-[#243F59] rounded px-3 py-2 text-base text-white Metropolis-Bold mb-3 mt-3 ml-1 mr-1 cursor-not-allowed opacity-50">▶▶</button>
                    }

                    <span className="bg-white rounded px-4 py-2 text-base text-[#243F59] Metropolis mt-5 ml-1 mr-1"><b>{page.total}</b> Registros</span>
                  </span>
                </div>
                <div className="overflow-hidden">

                  <table className="min-w-full border-x border-[#243F59] text-center rounded-[12px] overflow-hidden">
                    <thead className="border-x bg-[#243f59] rounded-[12px]">
                      <tr>
                        <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                          Tipo
                        </th>
                        <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                          Documento
                        </th>
                        <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                          Nombres
                        </th>
                        <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                          Apellidos
                        </th>
                        <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                          Correo
                        </th>
                        <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                          Acciones
                        </th>
                      </tr>
                    </thead>
                    <tbody className="border-b bg-[#fff]">
                      {Students.map((estudiante, index) => {
                        if (index % 2 === 0) {
                          classBg = "bg-[#ececec]";
                        } else {
                          classBg = "bg-[#fff]";
                        }
                        return (
                          <tr
                            key={estudiante.userid}
                            className={`border-b ${classBg}`}
                          >
                            <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r">
                              {estudiante.tipo_documento_str}
                            </td>
                            <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r">
                              {estudiante.documento}
                            </td>
                            <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r">
                              {estudiante.nombres}
                            </td>
                            <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r">
                              {estudiante.apellidos}
                            </td>
                            <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r">
                              {estudiante.correo}
                            </td>
                            <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r bg-[#243f5926] w-44">

                              <div className="flex justify-center">
                                {hasEditarPermission && (
                                  <Link
                                    className="text-blue-600 visited:text-black-600"
                                    to={`./edit/${estudiante.userid}`}
                                  >
                                    {" "}
                                    <img
                                      className="w-6"
                                      src={`/assets/icons/edit.png`}
                                      alt="Editar"
                                      title="Editar"
                                    />
                                  </Link>
                                )}
                                {hasVerPermission && (
                                  <Link
                                    className="text-blue-600 visited:text-black-600"
                                    to={`./view/${estudiante.userid}`}
                                  >
                                    {" "}
                                    <img
                                      className="w-6"
                                      src={`/assets/icons/details.png`}
                                      alt="Ver Detalles"
                                      title="Ver Detalles"
                                    />
                                  </Link>
                                )}
                                {hasBorrarPermission && (
                                  <img
                                    onClick={(event) => handleClick(estudiante.userid)}
                                    className="w-6"
                                    src={`/assets/icons/delete.png`}
                                    alt="Eliminar"
                                    title="Eliminar"
                                  />
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                <div className='grid justify-items-center bg-[#243F59] bg-opacity-30 rounded-b-xl ml-3 mr-3'>
                  <span>
                    {page.onFirstPage !== true ?
                      <button onClick={(event) => handleFirstPage(page.currentPage)} className="bg-[#243F59] rounded px-3 py-2 text-base text-white Metropolis-Bold mb-3 mt-3 ml-1 mr-1">◀◀</button>
                      :
                      <button disabled onClick={(event) => handleFirstPage(page.currentPage)} className="bg-[#243F59] rounded px-3 py-2 text-base text-white Metropolis-Bold mb-3 mt-3 ml-1 mr-1 cursor-not-allowed opacity-50">◀◀</button>
                    }

                    {page.onFirstPage !== true ?
                      <button onClick={(event) => handleBackPage(page.currentPage)} className="bg-[#243F59] rounded px-4 py-2 text-base text-white Metropolis-Bold mb-3 mt-3 ml-1 mr-1">◀</button>
                      :
                      <button disabled onClick={(event) => handleBackPage(page.currentPage)} className="bg-[#243F59] rounded px-4 py-2 text-base text-white Metropolis-Bold mb-3 mt-3 ml-1 mr-1 cursor-not-allowed opacity-50">◀</button>
                    }

                    <span className="bg-white rounded px-4 py-2 text-base text-[#243F59] Metropolis mt-5 ml-1 mr-1">Página <b>{page.currentPage}</b> de <b>{page.lastPage}</b></span>

                    {page.hasMorePages === true ?
                      <button onClick={(event) => handleNextPage(page.currentPage)} className="bg-[#243F59] rounded px-4 py-2 text-base text-white Metropolis-Bold mb-3 mt-3 ml-1 mr-1">▶</button>
                      :
                      <button disabled onClick={(event) => handleNextPage(page.currentPage)} className="bg-[#243F59] rounded px-4 py-2 text-base text-white Metropolis-Bold mb-3 mt-3 ml-1 mr-1 cursor-not-allowed opacity-50">▶</button>
                    }

                    {page.hasMorePages !== false ?
                      <button onClick={(event) => handleLastPage(page.lastPage)} className="bg-[#243F59] rounded px-3 py-2 text-base text-white Metropolis-Bold mb-3 mt-3 ml-1 mr-1">▶▶</button>
                      :
                      <button disabled onClick={(event) => handleLastPage(page.lastPage)} className="bg-[#243F59] rounded px-3 py-2 text-base text-white Metropolis-Bold mb-3 mt-3 ml-1 mr-1 cursor-not-allowed opacity-50">▶▶</button>
                    }

                    <span className="bg-white rounded px-4 py-2 text-base text-[#243F59] Metropolis mt-5 ml-1 mr-1"><b>{page.total}</b> Registros</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
    </>


  );
};
