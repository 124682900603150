import React from "react";
import { Routes, Route } from "react-router-dom";

import { CreateCertificates } from "../components/certificate/CreateCertificates";
import { ViewCertificates } from "../components/certificate/ViewCertificates";
import { ListCertificates } from "../components/certificate/ListCertificates";
import { EditCertificates } from "../components/certificate/EditCertificates";
import { Navbar } from "../components/ui/Navbar";
import { SideBarr } from "../components/ui/SideBarr";
import { VisualizationOfStudentsByCourse } from "../components/certificate/VisualizationOfStudentsByCourse";
import { ListCertificatesStudents } from "../components/certificate/ListCertificatesStudents";
import { permisos } from "../helper/permissions";

export const CertificateRoutes = () => {
  const hasCrearPermission = permisos('Cursos', 'crear')
  return (
    <div className="flex "> 
      <SideBarr titulo={"Certificados"} />
      <div className={`h-screen w-full`}>
        <Navbar titulo={"Cursos IU Digital"} nameModule={'Curso'} phat={'/certificate/create'} butonCreate={true} hasCrearPermission={hasCrearPermission} />
        <Routes>
          <Route path="list-students" element={<ListCertificatesStudents />} />
          <Route path="list" element={<ListCertificates />} />
          <Route path="" element={<ListCertificates />} />
          <Route path="view/:id" element={<ViewCertificates />} />
          <Route path="edit/:id" element={<EditCertificates />} />
          <Route path="create" element={<CreateCertificates />} />
          <Route path="list-student/:id" element={<VisualizationOfStudentsByCourse />} />
        </Routes>
      </div>
    </div>
  );
};
