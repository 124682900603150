import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { useForm } from "../../hooks/useForm";
import { useNavigate, useParams } from "react-router-dom";
import {
  editStudentServices,
  getStudentByIdToEditService,
  deleteCertificatesService,
  createCertificateService,
  getCertificatesService,
  editEstudentByCertificateService,
} from "../../selectors/getStudents";
import { getIDTypeService } from "../../selectors/idtypeServices";
import { BackToPage } from "../generals/backToPage/BackToPage";
import { permisos } from "../../helper/permissions";

export const EditStudents = () => {

  const hasEditarPermission = permisos('Estudiantes', 'editar')

  permisos('Estudiantes', 'editar', 'alerta')

  const seccion = 'Certificados'
  const hasCertificadosListarPermission = permisos(seccion, 'listar')
  const hasCertificadosBorrarPermission = permisos(seccion, 'borrar')
  const hasCertificadosCrearPermission = permisos(seccion, 'crear')
  const hasCertificadosEditarPermission = permisos(seccion, 'editar')


  let classBg = "bg-[#fff]";
  const params = useParams();
  let defaultValues = {};
  const [imgClassModal, setImgClassModal] = useState("");
  const [imgClassModal2, setImgClassModal2] = useState("");
  const [defaultCertificates, setDefaultCertificates] = useState([]);
  const [tipoDocumento, setIDType] = useState([]);
  const navigate = useNavigate();
  const [camposInvalidosForm, setCamposInvalidosForm] = useState([]);

  useEffect(() => {
    getStudentByIdToEditService(parseFloat(params.id))
      .then((resp) => {
        if (resp.data.success) {
          if (hasCertificadosListarPermission) {
            setDefaultCertificates(resp.data.data.certificados);
          }
        } else {
          if (resp.data.code == 401) {
            swal({
              title: "Alerta!!!",
              text: `${resp.data.data.message}`,
              icon: "warning",
              dangerMode: true,
            }).then(function () {
              window.location = "/bienvenido";
            });
          }
        }
      })
      .catch((error) => {
        swal({
          title: "Error",
          text: `Sucedió un error: ${error}`,
          icon: "error",
          dangerMode: true,
        });
      });
    getIDTypeService()
      .then((resp) => {
        if (resp.data.success) {
          setIDType(resp.data.data);
        }
      })
      .catch((error) => {
        swal({
          title: "Error",
          text: `Sucedió un error: ${error}`,
          icon: "error",
          dangerMode: true,
        });
      });
  }, []);

  const estilo =
    "Metropolis-Bold placeholder:text-slate-400 block bg-white w-full rounded-md mx-1 py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm";

  const cargarData = () => {
    const Student = JSON.parse(localStorage.getItem("Students"));
    const StudentId = Student.find(
      (resp) => resp.userid === parseFloat(params.id)
    );
    return StudentId;
  };
  defaultValues = cargarData();

  const [formValues, handleInputChange] = useForm({
    userid: defaultValues.userid,
    tipo_registro: defaultValues.tipo_registro,
    tipo_documento: defaultValues.tipo_documento,
    documento: defaultValues.documento,
    nombres: defaultValues.nombres,
    apellidos: defaultValues.apellidos,
    correo: defaultValues.correo,
    fecha_carga: defaultValues.fecha_carga,
  });

  const [fomValuesCertificateByStudent, handleInputChangeCertificate] = useForm(
    {
      id: "",
      documentoEdit: "",
      codigoCursoEdit: "",
      tipoCertificacionEdit: "",
      calificacionEdit: "",
    }
  );

  const {
    id,
    documentoEdit,
    codigoCursoEdit,
    tipoCertificacionEdit,
    calificacionEdit,
  } = fomValuesCertificateByStudent;
  const { tipo_documento, documento, nombres, apellidos, correo } = formValues;

  const handleEdit = (e) => {
    e.preventDefault();
    let camposInvalidos = obtenerCamposInvalidosDelForm(formValues);
    if (camposInvalidos.length > 0) {
      setCamposInvalidosForm(camposInvalidos);
    } else {
      editStudentServices(formValues)
        .then((resp) => {
          if (resp.data.success) {
            swal("Estudiante editado con Éxito", {
              icon: "success",
            });
            navigate("../");
          } else {
            if (resp.data.code == 401) {
              swal({
                title: "Alerta!!!",
                text: `${resp.data.data.message}`,
                icon: "warning",
                dangerMode: true,
              }).then(function () {
                window.location = "/bienvenido";
              });
            } else {
              swal({
                title: "Error",
                text: `Error: ${JSON.stringify(resp.data.data.message)}`,
                icon: "error",
                dangerMode: true,
              });
            }

          }
        })
        .catch((error) => {
          swal({
            title: "Error",
            text: `Sucedió un error: ${error}`,
            icon: "error",
            dangerMode: true,
          });
        });
    }
  };

  const handleAddCertificate = (e) => {
    e.preventDefault();
    createCertificateService(formValues)
      .then((resp) => {
        if (resp.data.success) {
          swal("Certificado asociado con Éxito", {
            icon: "success",
          });

          setImgClassModal("");

          getCertificatesService(formValues.documento).then((resp) => {
            setDefaultCertificates(resp.data.data.certificados);

          });

        } else {
          if (resp.data.code == 401) {
            swal({
              title: "Alerta!!!",
              text: `${resp.data.data.message}`,
              icon: "warning",
              dangerMode: true,
            }).then(function () {
              window.location = "/bienvenido";
            });
          } else {
            swal({
              title: "Error",
              text: resp.data.data[0],
              icon: "error",
              dangerMode: true,
            });
          }

        }
      })
      .catch((error) => {
        swal({
          title: "Error",
          text: `Sucedió un error: ${error}`,
          icon: "error",
          dangerMode: true,
        });
      });
  };
  const handleEditCertificate = (e) => {
    e.preventDefault();
    const data = {
      id: fomValuesCertificateByStudent.id,
      documento: fomValuesCertificateByStudent.documentoEdit,
      codigoCurso: fomValuesCertificateByStudent.codigoCursoEdit,
      tipoCertificacion: fomValuesCertificateByStudent.tipoCertificacionEdit,
      calificacion: parseFloat(fomValuesCertificateByStudent.calificacionEdit),
    };
    editEstudentByCertificateService(data).then((resp) => {
      /**Respuesta */
      if (resp.data.success) {
        getStudentByIdToEditService(parseFloat(params.id))
          .then((resp) => {
            if (resp.data.success) {
              setDefaultCertificates(resp.data.data.certificados);
              setImgClassModal2("");
            }
          })
          .catch((error) => {
            swal({
              title: "Error",
              text: `Sucedió un error: ${error}`,
              icon: "error",
              dangerMode: true,
            });
          });
      } else {
        if (resp.data.code == 401) {
          swal({
            title: "Alerta!!!",
            text: `${resp.data.data.message}`,
            icon: "warning",
            dangerMode: true,
          }).then(function () {
            window.location = "/bienvenido";
          });
        }
      }
    });
  };

  const handleClickCloseOpendModal = (swicther) => {
    if (swicther) {
      setImgClassModal("show");
    } else {
      setImgClassModal("");
    }
  };

  const onKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode >= 48 && keyCode <= 57) {
      console.log(keyCode)
      return true
    } else if (keyCode == 46) {
      return true;
    } else {
      event.preventDefault();
    }
  }

  const handleEditClick = (certificate) => {
    fomValuesCertificateByStudent.id = certificate.certificadoId;
    fomValuesCertificateByStudent.documentoEdit = certificate.documento;
    fomValuesCertificateByStudent.codigoCursoEdit = certificate.codigoCurso;
    fomValuesCertificateByStudent.tipoCertificacionEdit =
      certificate.tipoCertificacion;
    fomValuesCertificateByStudent.calificacionEdit = certificate.calificacion;

    setImgClassModal2("show");
  };

  const handleClick = (id) => {
    swal({
      title: "Alerta!!!",
      text: "¿Está seguro que desea eliminar el registro?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let nuevaListaDeCertificados = defaultCertificates.filter(
          (certificado) => certificado.certificadoId !== id
        );

        deleteCertificatesService(id)
          .then((resp) => {
            if (resp.data.success) {
              setDefaultCertificates(nuevaListaDeCertificados);
              swal("certificados Eliminado con Éxito", {
                icon: "success",
              });
            } else {
              if (resp.data.code == 401) {
                swal({
                  title: "Alerta!!!",
                  text: `${resp.data.data.message}`,
                  icon: "warning",
                  dangerMode: true,
                }).then(function () {
                  window.location = "/bienvenido";
                });
              } else {
                swal({
                  title: "Error",
                  text: resp.data.data[0],
                  icon: "error",
                  dangerMode: true,
                });
              }

            }
          })
          .catch((error) => {
            swal({
              title: "Error",
              text: `Sucedió un error: ${error}`,
              icon: "error",
              dangerMode: true,
            });
          });
      } else {
        swal("No se alteró la base de datos");
      }
    });
  };
  const obtenerCamposInvalidosDelForm = (formValues) => {
    let camposInvalidos = [];
    if (formValues.tipo_documento === "") {
      camposInvalidos.push("tipo_documento");
    }
    if (formValues.documento === "") {
      camposInvalidos.push("documento");
    }
    if (formValues.nombres === "") {
      camposInvalidos.push("nombres");
    }
    if (formValues.apellidos === "") {
      camposInvalidos.push("apellidos");
    }
    if (formValues.correo === "") {
      camposInvalidos.push("correo");
    }
    return camposInvalidos;
  };

  return (
    <>
      {camposInvalidosForm.includes(
        "tipo_documento",
        "documento",
        "nombres",
        "apellidos",
        "correo"
      ) && (
          <span className="flex bg-[#FBE9EA] px-6 py-2 mt-5 ml-10 mr-10 border-l-4 border-l-rose-600 text-base text-[#4d4d4d] Metropolis">
            <b>Advertencia:</b>&nbsp;Los campos marcados en Rojo son Obligatorios
          </span>
        )}

      {
        hasEditarPermission && (
          <div>
            <form onSubmit={handleEdit}>
              <div className="mt-3">
                <h1 className="text-center text-base lg:text-3xl my-5 Metropolis-Extra-Bold">
                  Editar Estudiante
                </h1>
                <div className="flex mt-3">
                  <div className="w-3/4 m-auto px-5 py-5 rounded-2xl bg-[#67b5dd]">
                    <div className="flex">
                      <div className="w-2/6 rounded-lg  max-full mx-2 bg-[#243f59] Metropolis-Bold text-2xl text-white leading-loose">
                        <ul>
                          <li className="pl-3">
                            <label>Tipo de documento</label>
                          </li>
                          <li className="pl-3">
                            <label>Documento</label>
                          </li>
                          <li className="pl-3">
                            <label>Nombre(s)</label>
                          </li>
                          <li className="pl-3">
                            <label>Apellido(s)</label>
                          </li>
                          <li className="pl-3">
                            <label>Correo</label>
                          </li>
                        </ul>
                      </div>
                      <div className="w-4/6">
                        <div className="mb-3">
                          <select
                            className={
                              camposInvalidosForm.includes("tipo_documento")
                                ? estilo + " border-2 border-rose-600"
                                : estilo
                            }
                            name="tipo_documento"
                            value={tipo_documento}
                            onChange={handleInputChange}
                          >
                            <option calue="">Seleccione Tipo de Documento</option>
                            {tipoDocumento.map((tipo, index) => {
                              return (
                                <option
                                  key={index}
                                  value={tipo.tipo_documentoId}
                                  selected={tipo_documento === tipo.tipo_documentoId}
                                >
                                  {tipo.tipo_documento}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="mb-3 ">
                          <input
                            type="text"
                            placeholder="Numero de Documento"
                            className={
                              camposInvalidosForm.includes("documento")
                                ? estilo + " border-2 border-rose-600"
                                : estilo
                            }
                            name="documento"
                            autoComplete="off"
                            value={documento}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="mb-3 ">
                          <input
                            type="text"
                            placeholder="Nombre(s)"
                            className={
                              camposInvalidosForm.includes("nombres")
                                ? estilo + " border-2 border-rose-600"
                                : estilo
                            }
                            name="nombres"
                            autoComplete="off"
                            value={nombres}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="mb-3">
                          <input
                            type="text"
                            placeholder="Apellido(s)"
                            className={
                              camposInvalidosForm.includes("apellidos")
                                ? estilo + " border-2 border-rose-600"
                                : estilo
                            }
                            name="apellidos"
                            autoComplete="off"
                            value={apellidos}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="mb-3">
                          <input
                            type="email"
                            placeholder="E-mail"
                            className={
                              camposInvalidosForm.includes("correo")
                                ? estilo + " border-2 border-rose-600"
                                : estilo
                            }
                            name="correo"
                            autoComplete="off"
                            value={correo}
                            onChange={handleInputChange}
                          />
                        </div>
                        <input
                          className="bg-[#93b33a] rounded px-6 py-2 text-base text-white Metropolis-Bold"
                          type="submit"
                          value={"Guardar"}
                        />
                        <BackToPage texto="Cancelar" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="flex flex-col px-8 mt-5">
              <div className="overflow-x-auto">
                <div className="p-8 inline-block min-w-full bg-[#67b5dd] rounded-[12px] ">
                  <div className="overflow-hidden relative ">
                    <h2 className="text-3xl Metropolis-Extra-Bold color-titulos mb-4">
                      Certificados Asociados
                    </h2>
                    {
                      hasCertificadosListarPermission ? (
                        <div>
                          {
                            hasCertificadosCrearPermission && (
                              <button
                                className="bg-[#93b33a] rounded px-6 py-2 text-base text-white Metropolis-Bold absolute right-0 top-0"
                                onClick={() => handleClickCloseOpendModal(true)}
                              >
                                Crear Certificado
                              </button>
                            )
                          }

                          <table className="min-w-full border text-center rounded-[12px] overflow-hidden">
                            <thead className="border-b bg-[#243f59] rounded-[12px]">
                              <tr>
                                <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                                  #
                                </th>
                                <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                                  Código Curso
                                </th>
                                <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                                  Tipo de Registro
                                </th>
                                <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                                  Información de curso
                                </th>
                                <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                                  Acciones
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {defaultCertificates.map((certificate, index) => {
                                if (index % 2 === 0) {
                                  classBg = "bg-[#ececec]";
                                } else {
                                  classBg = "bg-[#fff]";
                                }
                                return (
                                  <tr key={index} className={`border-b ${classBg}`}>
                                    <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r bg-[#243f5926]">
                                      {index + 1}
                                    </td>
                                    <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r">
                                      {certificate.codigoCurso}
                                    </td>
                                    <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r">
                                      {certificate.tipo_registro}
                                    </td>
                                    <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r">
                                      <ul className="text-left px-5">
                                        <li>
                                          <strong>Nombre Curso: </strong>{" "}
                                          <span>
                                            {certificate.info_curso.nombreCurso}{" "}
                                            {certificate.info_curso.nombreCurso2}
                                          </span>
                                        </li>
                                        <li>
                                          <strong>Horas: </strong>{" "}
                                          <span>{certificate.info_curso.horas}</span>
                                        </li>
                                        <li>
                                          <strong>Fecha de Certificación: </strong>{" "}
                                          <span>
                                            {certificate.fechaCarga}
                                          </span>
                                        </li>
                                        <li>
                                          <strong>Calificación: </strong>
                                          <span>{certificate.calificacion}</span>
                                          {certificate.calificacion === null && (
                                            <span>Sin Calificacion</span>
                                          )}
                                        </li>
                                      </ul>
                                    </td>
                                    <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r bg-[#243f5926]">

                                      <div className="flex justify-center">
                                        {
                                          hasCertificadosBorrarPermission && (
                                            <img
                                              onClick={(event) =>
                                                handleClick(certificate.certificadoId)
                                              }
                                              className="w-6"
                                              src={`/assets/icons/delete.png`}
                                              alt=""
                                            />
                                          )
                                        }

                                        {
                                          hasCertificadosEditarPermission && (
                                            <img
                                              className="w-6"
                                              src={`/assets/icons/edit.png`}
                                              alt="Editar"
                                              onClick={(event) => handleEditClick(certificate)}
                                              title="Editar"
                                            />
                                          )
                                        }


                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          <BackToPage texto="Cancelar" />
                        </div>
                      ) : (
                        <div>
                          No tienes permiso para ver los certificados del estudiante
                        </div>
                      )

                    }
                  </div>
                </div>
              </div>

              <div className={`${imgClassModal2} modal_img `}>
                <button
                  className="btn dimmisingModal op-1/4"
                  onClick={() => setImgClassModal2(false)}
                >
                  <img src="/assets/images/close.svg" alt="" />
                </button>
                <form onSubmit={handleEditCertificate}>
                  <div className="mt-3 absolute w-full top-1/4">
                    <div className="flex mt-3">
                      <div className="w-3/4 m-auto px-5 py-5 rounded-2xl bg-[#67b5dd]">
                        <div className="flex">
                          <h2 className="text-3xl Metropolis-Extra-Bold color-titulos mb-4">
                            Editar Certificado
                          </h2>
                          <div className="w-2/6 rounded-lg  max-full mx-2 bg-[#243f59] Metropolis-Bold text-2xl text-white leading-loose">
                            <ul>
                              <li className="pl-3">
                                <label>Código Curso</label>
                              </li>
                              <li className="pl-3">
                                <label>Calificación</label>
                              </li>
                             
                            </ul>
                          </div>
                          <div className="w-4/6">
                            <div className="mb-3">
                              <input
                                type="text"
                                placeholder="Código Curso"
                                className={estilo}
                                name="codigoCursoEdit"
                                value={codigoCursoEdit}
                                onChange={handleInputChangeCertificate}
                              />
                            </div>

                            <div className="mb-3 ">
                              <input
                                type="number"
                                placeholder="Calificación"
                                className={estilo}
                                name="calificacionEdit"
                                value={calificacionEdit}
                                onKeyPress={(event) => { onKeyPress(event) }}
                                max="5"
                                min="0"
                                step=".01"
                                onChange={handleInputChangeCertificate}
                              />
                            </div>
                    
                            <input
                              className="bg-[#93b33a] rounded px-6 py-1 text-base text-white Metropolis-Bold"
                              type="submit"
                              value={"Guardar"}
                            />

                            <a
                              className="bg-[#AD2A3F] rounded px-6 mx-3 py-2 text-base text-white Metropolis-Bold"
                              onClick={() => setImgClassModal2(false)}
                            >
                              Cancelar
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {
                hasCertificadosCrearPermission && (
                  <div className={`${imgClassModal} modal_img `}>
                    <button
                      className="btn dimmisingModal top-1/4"
                      onClick={() => handleClickCloseOpendModal(false)}
                    >
                      <img src="/assets/images/close.svg" alt="" />
                    </button>
                    <form onSubmit={handleAddCertificate}>
                      <div className="mt-3 absolute w-full top-1/4">
                        <div className="flex mt-3">
                          <div className="w-3/4 m-auto px-5 py-5 rounded-2xl bg-[#67b5dd]">
                            <div className="flex">
                              <h2 className="text-3xl Metropolis-Extra-Bold color-titulos mb-4">
                                Crear Certificado
                              </h2>
                              <div className="w-2/6 rounded-lg  max-full mx-2 bg-[#243f59] Metropolis-Bold text-2xl text-white leading-loose">
                                <ul>
                                  <li className="pl-3">
                                    <label>Código Curso</label>
                                  </li>
                                  <li className="pl-3">
                                    <label>Calificación</label>
                                  </li>
                                </ul>
                              </div>
                              <div className="w-4/6">
                                <div className="mb-3">
                                  <input
                                    type="text"
                                    placeholder="Código Curso"
                                    className={estilo}
                                    name="codigoCurso"
                                    autoComplete="off"
                                    onChange={handleInputChange}
                                  />
                                  <div className="Cursos"></div>
                                </div>

                                <div className="mb-3 ">
                                  <input
                                    type="number"
                                    placeholder="Calificación"
                                    className={estilo}
                                    name="calificacion"
                                    autoComplete="off"
                                    onKeyPress={(event) => { onKeyPress(event) }}
                                    max="5"
                                    min="0"
                                    step=".01"
                                    onChange={handleInputChange}
                                  />
                                </div>
                                <input
                                  className="bg-[#93b33a] rounded px-6 py-1 text-base text-white Metropolis-Bold"
                                  type="submit"
                                  value={"Guardar"}
                                />

                                <a
                                  className="bg-[#AD2A3F] rounded px-6 mx-3 py-2 text-base text-white Metropolis-Bold"
                                  onClick={() => handleClickCloseOpendModal(false)}
                                >
                                  Cancelar
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                )
              }

            </div>
          </div>
        )}
    </>
  );
};
