import React, { useEffect, useState } from 'react'
import swal from "sweetalert";
import { useParams } from 'react-router-dom';
import { getSingelImageStorageService } from '../../selectors/imageStorageService'
import { DetailComponent } from '../generals/detailComponent/DetailComponent';
import { permisos } from "../../helper/permissions";

export const ViewImageStorage = () => {

  const seccion = 'Galeria'
  permisos(seccion, 'listar', 'alerta')
  const hasListarPermission = permisos(seccion, 'listar')
  const hasEditarPermission = permisos(seccion, 'editar')
  const hasVerPermission = permisos(seccion, 'ver')
  const hasBorrarPermission = permisos(seccion, 'borrar')
  const hasCrearPermission = permisos(seccion, 'crear')

  const [defaultValues, setDefaultValues] = useState({});
  const [imgClassModal, setImgClassModal] = useState('');
  const params = useParams();
  useEffect(() => {
    getSingelImageStorageService(params.id).then(resp => {
      if (resp.data.success) {
        const result = { ...defaultValues, ...resp.data.data };
        setDefaultValues(result)
      } else {
        if (resp.data.code == 401) {
          swal({
            title: "Alerta!!!",
            text: `${resp.data.data.message}`,
            icon: "warning",
            dangerMode: true,
          }).then(function () {
            window.location = "/bienvenido";
          });
        }
      }
    }).catch((error) => {
      swal({
        title: "Error",
        text: `Sucedió un error: ${error}`,
        icon: "error",
        dangerMode: true,
      });
    });
  }, [])
  const data = {
    type: "Galería de imágenes",
    content: [
      {
        name: "Nombre",
        value: defaultValues.title,
      },
      {
        name: "Tipo",
        value: defaultValues.type,
      },
      {
        name: "Descripción",
        value: defaultValues.description,
      }
    ],
  };
  const handleClickCloseOpendModal = (swicther) => {
    if (swicther) {
      setImgClassModal('show');
    } else {
      setImgClassModal('');
    }
  }
  return (
    <>
      {hasVerPermission && (
        <div>
          <DetailComponent info={data} />
          <div className="w-3/4 m-auto px-5 py-5 rounded-2xl bg-[#67b5dd] mt-5">
            <div className="mb-3">
              <h1 className="text-center text-white lg:text-3xl my-5 Metropolis-Extra-Bold">
                Screen Imagen
              </h1>
              <img
                alt=""
                className="w-auto m-auto"
                src={`${process.env.REACT_APP_IMAGES_URL}${defaultValues.picture}`}
                onClick={() => handleClickCloseOpendModal(true)}
              />
            </div>
          </div>
          <div className={`${imgClassModal} modal_img`}>
            <button
              className="btn dimmisingModal"
              onClick={() => handleClickCloseOpendModal(false)} >
              <img src="/assets/images/close.svg" alt="" />
            </button>

            <div className="contentImagen">
              <img
                alt=""
                className="w-full m-auto"
                src={`${process.env.REACT_APP_IMAGES_URL}${defaultValues.picture}`}
              />
            </div>
          </div>
        </div>
      )}

    </>
  )
}
