import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { useParams } from "react-router-dom";
import { getStudentByIdService } from "../../selectors/getStudents";
import { DetailComponent } from "../generals/detailComponent/DetailComponent";
import { permisos } from "../../helper/permissions";

export const ViewStudents = () => {
  const seccion = 'Estudiantes'
  permisos(seccion, 'listar', 'alerta')
  const hasListarPermission = permisos(seccion, 'listar')
  const hasEditarPermission = permisos(seccion, 'editar')
  const hasVerPermission = permisos(seccion, 'ver')
  const hasBorrarPermission = permisos(seccion, 'borrar')
  const hasCrearPermission = permisos(seccion, 'crear')
  
  const [defaultValues, setDefaultValues] = useState({})
  const [defaultCertificates, setDefaultCertificates] = useState([])
  const params = useParams();
  let classBg = "bg-[#fff]";
  useEffect(() => {
    getStudentByIdService(parseFloat(params.id)).then((resp) => {
      if (resp.data.success) {
        const result = { ...defaultValues, ...resp.data.data.estudiante }
        setDefaultValues(result);
        setDefaultCertificates(resp.data.data.certificados)
      } else {
        if (resp.data.code == 401) {
          swal({
            title: "Alerta!!!",
            text: `${resp.data.data.message}`,
            icon: "warning",
            dangerMode: true,
          }).then(function () {
            window.location = "/bienvenido";
          });
        }
      }
    }).catch((error) => {
      swal({
        title: "Error",
        text: `Sucedió un error: ${error}`,
        icon: "error",
        dangerMode: true,
      });
    });
  }, [])
  const data = {
    type: "Estudiantes",
    content: [
      {
        name: "Tipo",
        value: defaultValues.tipo_documento_str,
      },
      {
        name: "Documento",
        value: defaultValues.documento,
      },
      {
        name: "Nombre(s)",
        value: defaultValues.nombres,
      },
      {
        name: "Apellido(s)",
        value: defaultValues.apellidos,
      },
      {
        name: "Correo",
        value: defaultValues.correo,
      },
    ],
  };
  return (
    <>
      {hasVerPermission && (
        <DetailComponent info={data} />
      )}
      {hasVerPermission && (

        <div className="flex flex-col px-8 mt-5">
          <div className="overflow-x-auto">
            <div className="p-8 inline-block min-w-full bg-[#67b5dd] rounded-[12px] ">

              <div className="overflow-hidden">
                <h2 className='text-3xl Metropolis-Extra-Bold color-titulos mb-4'>Certificados Asociados</h2>
                <table className="min-w-full border text-center rounded-[12px] overflow-hidden">
                  <thead className="border-b bg-[#243f59] rounded-[12px]">
                    <tr>
                      <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                        #
                      </th>
                      <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                        Código Curso
                      </th>
                      <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                        Tipo de Registro
                      </th>
                      <th className="text-sm text-white Metropolis-Bold px-4 py-4 text-left border-r">
                        Información de curso
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      defaultCertificates.map((certificate, index) => {
                        if (index % 2 === 0) {
                          classBg = "bg-[#ececec]";
                        } else {
                          classBg = "bg-[#fff]";
                        }
                        return (
                          <tr
                            key={index}
                            className={`border-b ${classBg}`}>
                            <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r bg-[#243f5926]">{index + 1}</td>
                            <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r">{certificate.codigoCurso}</td>
                            <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r">{certificate.tipo_registro}</td>
                            <td className="text-sm text-gray-900 Metropolis-Light px-4 py-4 border-r">
                              <ul className="text-left px-5">
                                <li><strong>Nombre Curso: </strong> <span>{certificate.info_curso.nombreCurso} {certificate.info_curso.nombreCurso2}</span></li>
                                <li><strong>Horas: </strong> <span>{certificate.info_curso.horas}</span></li>
                                <li><strong>Fecha de Certificación: </strong> <span>{certificate.info_curso.fechaCertificacion}</span></li>
                                <li><strong>Calificación: </strong> <span>{certificate.calificacion}</span>
                                  {
                                    (certificate.calificacion === null) &&
                                    <span>Sin Calificacion</span>
                                  }</li>
                              </ul>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}


    </>
  );
};
