import axios from 'axios';

let token = '';
if (localStorage.getItem('user')) {
    token = JSON.parse(localStorage.getItem('user'))['api_token'];
}
export const getAllUsersService = (page = 1,txt = "") => {
    if(txt !== ""){
        return axios.get(`${process.env.REACT_APP_API_URL}/usuarios?page=${page}&buscador=${txt}&api_token=${token}`);
    }else{
        return axios.get(`${process.env.REACT_APP_API_URL}/usuarios?page=${page}&api_token=${token}`);
    }
}

export const getUserByIdService = (id) => {

    if (id === '') {
        throw new Error('El Id ingresado es invalido');
    }

    return axios.get(`${process.env.REACT_APP_API_URL}/usuario/${id}?api_token=${token}`);

}

export const editUserService = (data) => {

    return axios.put(`${process.env.REACT_APP_API_URL}/usuario/update/${data.id}?api_token=${token}`, data)

}

export const createUsersService = (data) => {

    return axios.post(`${process.env.REACT_APP_API_URL}/usuario/create?api_token=${token}`, data);
}

export const deleteUsersServices = (id) => {
    if (id === '') {
        throw new Error('Invalid User');
    }
    return axios.delete(`${process.env.REACT_APP_API_URL}/usuario/delete/${id}?api_token=${token}`);
}